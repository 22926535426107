import React, { useState, useEffect } from 'react';
import { EditorContent, useEditor } from '@tiptap/react';
import PropTypes from 'prop-types';
import StarterKit from '@tiptap/starter-kit';
import Link from '@tiptap/extension-link';
import Bold from '@tiptap/extension-bold';
import Italic from '@tiptap/extension-italic';
import Strike from '@tiptap/extension-strike';
import Underline from '@tiptap/extension-underline';
import ListItem from '@tiptap/extension-list-item';
import BulletList from '@tiptap/extension-bullet-list';
import Placeholder from '@tiptap/extension-placeholder';
import { useDispatch, useSelector } from 'react-redux';
import { noteCreationSuccessSelector } from 'app/store/selectors/note';
import { Button } from 'app/components'
import { toggleEditNote, updateNote, createNote } from 'app/store/actions/note';
import './index.scss';

const MAX_CHARS = 500;

const NoteEditor = ({ entityType, entityId, merchantId, vendorId, editableNote }) => {
  const dispatch = useDispatch();
  const [isPrivate, setIsPrivate] = useState(false);
  const [noteContent, setNoteContent] = useState(null);
  const noteCreationSuccess = useSelector(noteCreationSuccessSelector);

  const toggleEditMode = () => {
    dispatch(toggleEditNote({ id: editableNote.id }));
  };

  const updateNoteFn = (note) => {
    dispatch(updateNote(note));
  };

  const editor = useEditor({
    extensions: [
      StarterKit,
      Link,
      Bold,
      Italic,
      Underline,
      ListItem,
      BulletList,
      Strike,
      Strike,
      Placeholder.configure({
        placeholder: 'Write a note here…',
      }),
    ],
    onUpdate({ editor }) {
      const text = editor.getText();
      if (text.length <= MAX_CHARS) {
        setNoteContent(text);
      } else {
        editor.commands.setContent(text.substring(0, MAX_CHARS));
        setNoteContent(text.substring(0, MAX_CHARS));
      }
    },
  });

  const handlePrivateChange = () => {
    setIsPrivate(!isPrivate);
  };

  const handleSubmit = () => {
    if (editor && noteContent && noteContent.length > 0) {
      const content = editor.getHTML();

      const payload = {
        content: content,
        isPrivate: isPrivate,
        resourceReference: {
          entityId: entityId,
          entityType: entityType,
          merchantId: merchantId,
          vendorId: vendorId
        }
      };

      dispatch(createNote(payload));
    }
  };

  useEffect(() => {
    if (noteCreationSuccess) {
      editor.commands.setContent(null);  // Reset editor content
      setNoteContent(null);
    }
  }, [noteCreationSuccess]);

  useEffect(() => {
    if (editableNote?.content) {
      editor.commands.setContent(editableNote.content);
      setNoteContent(editableNote.content);
      setIsPrivate(editableNote.isPrivate);
    }
  }, [editableNote]);

  const isActive = (format) => {
    return editor?.isActive(format) ? 'active' : '';
  };

  const addLink = () => {
    const url = prompt('Enter the URL');
    if (url) {
      editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run();
    }
  };

  return (
    <div className="note-editor-container">
      <div className="editor-frame">
        <EditorContent editor={editor} className="editor-content" />
        <div className="editor-toolbar">
          <div className="editor-buttons">
            <button onClick={() => editor.chain().focus().toggleBold().run()} className={`${isActive('bold')} editor-button bold-button`}>B</button>
            <button onClick={() => editor.chain().focus().toggleItalic().run()} className={`${isActive('italic')} editor-button italic-button`}>I</button>
            <button onClick={() => editor.chain().focus().toggleStrike().run()} className={`${isActive('strike')} editor-button strike-button`}>S</button>
            <button onClick={() => editor.chain().focus().toggleUnderline().run()} className={`${isActive('underline')} editor-button underline-button`}>U</button>
            <button onClick={() => editor.chain().focus().toggleBulletList().run()} className={`${isActive('bulletList')} editor-button`}>
              {isActive('bulletList') == 'active' ?
                (<svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="4" cy="12" r="2" fill="currentColor" />
                  <circle cx="4" cy="6" r="2" fill="currentColor" />
                  <circle cx="4" cy="18" r="2" fill="currentColor" />
                  <line x1="10" y1="12" x2="22" y2="12" stroke="currentColor" strokeWidth="2" />
                  <line x1="10" y1="6" x2="22" y2="6" stroke="currentColor" strokeWidth="2" />
                  <line x1="10" y1="18" x2="22" y2="18" stroke="currentColor" strokeWidth="2" />
                </svg>)
                :
                (<svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="4" cy="12" r="1" fill="currentColor" />
                  <circle cx="4" cy="6" r="1" fill="currentColor" />
                  <circle cx="4" cy="18" r="1" fill="currentColor" />
                  <line x1="10" y1="12" x2="22" y2="12" stroke="currentColor" strokeWidth="1" />
                  <line x1="10" y1="6" x2="22" y2="6" stroke="currentColor" strokeWidth="1" />
                  <line x1="10" y1="18" x2="22" y2="18" stroke="currentColor" strokeWidth="1" />
                </svg>)
              }
            </button>
            <button onClick={addLink} className={`${isActive('link')} editor-button`}>🔗</button>
            <label className="private-checkbox">
              <input type="checkbox" checked={isPrivate} onChange={handlePrivateChange} /> Mark as Private
            </label>
          </div>
          <div className="submit-section">
            <span className="char-count">{noteContent?.length || 0}/{MAX_CHARS}</span>
            {
              editableNote?.id ?
                (<div style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}>
                  <Button
                    variant="secondary"
                    size="small"
                    label="Cancel"
                    onClick={toggleEditMode}
                  />
                  <Button
                    variant="primary"
                    size="small"
                    label="Save"
                    className='edit-submit-button'
                    onClick={() => updateNoteFn({ id: editableNote.id, content: editor.getHTML(), isPrivate: isPrivate })}
                  />
                </div>) :
                (<button onClick={handleSubmit} className="submit-button">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                    <path d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 0 0-.082.887l.41.26.001.002 4.995 3.178 3.178 4.995.002.002.26.41a.5.5 0 0 0 .886-.083zm-1.833 1.89L6.637 10.07l-.215-.338a.5.5 0 0 0-.154-.154l-.338-.215 7.494-7.494 1.178-.471z" />
                  </svg>
                </button>)
            }
          </div>
        </div>
      </div>
    </div>
  );
};

NoteEditor.propTypes = {
  entityType: PropTypes.string.isRequired,
  entityId: PropTypes.string.isRequired,
  merchantId: PropTypes.string,
  vendorId: PropTypes.string,
  editableNote: PropTypes.shape({
    id: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    isPrivate: PropTypes.bool
  }),
};

export default NoteEditor;
