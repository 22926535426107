import axios from 'axios';
import Config from '../config';
import SetupInterceptors from './axiosInterceptors';

class OrderServices {
  constructor() {
    this.api = axios.create({
      baseURL: Config.ShipmentMS.Url
    });

    SetupInterceptors(this.api);
  }

  // get all the shipments in a particular order
  getShipmentsByOrderId = async (orderId) => {
    try {
      const response = await this.api.get(`/v1/order/${orderId}`);
      return response.data;
    } catch (error) {
      throw new Error('Error fetching data from API');
    }
  };

  updateShipmentById = async (shipmentId, data) => {
    try {
      const response = await this.api.patch(`/v1/${shipmentId}`, data);
      return response.data;
    } catch (error) {
      throw new Error('Error updating shipment');
    }
  };

  rerouteShipment = async (data) => {
    try {
      const response = await this.api.post(`/v1/reroute`, data);
      return response.data;
    } catch (error) {
      throw new Error('Error rerouting shipment');
    }
  };

  cancelShipment  = async (shipmentId, data) => {
    try {
      const response = await this.api.patch(`/v1/${shipmentId}/cancel`, data);
      return response.data;
    } catch (error) {
      throw new Error('Error cancelling shipment');
    }
  };

  rejectShipment = async (data) => {
    try {
      const response = await this.api.post(`/v1/reject`, data);
      return response.data;
    } catch (error) {
      throw new Error('Error rejecting shipment');
    }
  }

  updatePackageById = async (shipmentId, packageId, data) => {
    try {
      const response = await this.api.patch(`/v1/${shipmentId}/packrage/${packageId}`, data);
      return response.data;
    } catch (error) {
      throw new Error('Error updating package');
    }
  };

  unassignItem = async (shipmentId, data) => {
    try {
      const response = await this.api.post(`/v1/${shipmentId}/unassign`, data);
      return response.data;
    } catch (error) {
      throw new Error('Error unassigning item');
    }
  }
 
  getAuditInfo = async (auditUrl) => {
    try {
      auditUrl = auditUrl.replace(/.*shipment/, '');
      auditUrl = auditUrl.replace('DIFF', 'FULL');

      const response = await this.api.get(auditUrl);
      return response.data;
    }
    catch (error) {
      throw new Error('Error fetching audit info');
    }
  }
}

export default new OrderServices();