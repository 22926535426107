import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { array, object, string } from 'yup';
import { Modal, Button, Input } from 'app/components';
import { updatePackageById } from 'app/store/actions/shipment';
import './index.scss';

const EditTrackingModal = (props) => {
  const { shipment, showEditTrackingModal } = props;
  const dispatch = useDispatch();

  const [isSaveEnabled, setIsSaveEnabled] = useState(false);

  const checkIfChangesExist = (values) => {
    const hasChanges = shipment.packages.some(
      (pkg, index) => pkg.carrierTrackingNumber !== values.packages[index].carrierTrackingNumber
    );
    setIsSaveEnabled(hasChanges);
  };

  return (
    <Formik
      initialValues={{
        packages: shipment.packages.map(pkg => ({
          carrierTrackingNumber: pkg.carrierTrackingNumber || '',
        })),
      }}
      validationSchema={object().shape({
        packages: array().of(
          object().shape({
            carrierTrackingNumber: string().required('Tracking URL is required'),
          })
        ),
      })}
      onSubmit={(values) => {
        // filter out packages that have not changed
        const updatedPackages = shipment.packages.map((pkg, index) => ({
          pkgId: pkg.id,
          carrierTrackingNumber: values.packages[index].carrierTrackingNumber,
        })).filter((pkg, index) => pkg.carrierTrackingNumber !== shipment.packages[index].carrierTrackingNumber);

        if (updatedPackages.length > 0) {
          const data = {
            orderId: shipment.orderId,
            shipmentId: shipment.id,
            packages: updatedPackages,
          };

          dispatch(updatePackageById({ data }));
          showEditTrackingModal(null);
        }
      }}
    >
      {({
        values,
        errors,
        handleChange,
        handleSubmit,
        submitCount,
        setFieldValue,
      }) => {

        useEffect(() => {
          checkIfChangesExist(values);
        }, [values]);

        return (
          <Modal
            className="edit-tracking-modal"
            title={`Edit Shipment ${shipment.shortId || shipment.id}`}
            hideButtons={true}
            onClose={() => { showEditTrackingModal(null) }}
          >
            {shipment.packages.map((pkg, index) => (
              <div key={pkg.id} className="package-tracking-info">
                {shipment.packages.length > 1 && (
                  <label>{`Tracking Information Package ${index + 1}`}</label>
                )}
                <Input
                  label="Tracking URL"
                  name={`packages[${index}].carrierTrackingNumber`}
                  value={values.packages[index].carrierTrackingNumber}
                  onChange={(e) => {
                    handleChange(e);
                    setFieldValue(`packages[${index}].carrierTrackingNumber`, e.target.value);
                  }}
                  placeholder="Enter tracking URL"
                  errorMessage={submitCount > 0 && errors.packages && errors.packages[index]?.carrierTrackingNumber}
                />
              </div>
            ))}

            <div className="action-buttons">
              <Button
                variant="secondary"
                size="small"
                label="Cancel"
                onClick={() => { showEditTrackingModal(null) }}
              />
              <Button
                variant="primary"
                size="small"
                label="Save Changes"
                onClick={handleSubmit}
                disabled={!isSaveEnabled}
              />
            </div>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default EditTrackingModal;
