import React from 'react';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { object, string } from 'yup';
import { Card, Button, Input } from 'app/components';
import { updateFacility } from 'app/store/actions/vendor';
import './index.scss';

const AddEditShippingMapping = ({ vendorId, facilityId, shippingMapping, facilityDetails, backToShippingMapping }) => {
  const dispatch = useDispatch();

  return (
    <div className="add-edit-shipping-mapping-view">
      <Formik
        enableReinitialize
        initialValues={{
          carrierName: shippingMapping?.carrierName || '',
          shipmentMethod: shippingMapping?.shippingMethods?.[0]?.shipmentMethod || '',
          serviceMethodsAllowed: shippingMapping?.shippingMethods?.[0]?.carrierShippingServiceMethods?.[0]?.serviceMethodsAllowed.join(', ') || '',
          carrierCode: shippingMapping?.shippingMethods?.[0]?.carrierShippingServiceMethods?.[0]?.vendorMapping?.carrierCode || '',
          serviceCode: shippingMapping?.shippingMethods?.[0]?.carrierShippingServiceMethods?.[0]?.vendorMapping?.serviceCode || '',
          countryCodesToApply: shippingMapping?.shippingMethods?.[0]?.carrierShippingServiceMethods?.[0]?.countryCodesToApply.join(', ') || '',
        }}
        validationSchema={object().shape({
          carrierName: string().required('Enter a Carrier Name'),
          shipmentMethod: string().required('Enter a Shipment Method'),
          serviceMethodsAllowed: string().required('Enter Services Method'),
          carrierCode: string().required('Enter a Carrier Code'),
          serviceCode: string().required('Enter a Service Code'),
          countryCodesToApply: string().required('Enter Country Codes to Apply'),
        })}
        onSubmit={(values) => {
          const newMapping = {
            carrierName: values.carrierName,
            shippingMethods: [
              {
                shipmentMethod: values.shipmentMethod,
                carrierShippingServiceMethods: [
                  {
                    serviceMethodsAllowed: values.serviceMethodsAllowed.split(',').map(s => s.trim()),
                    countryCodesToApply: values.countryCodesToApply.split(',').map(c => c.trim()),
                    vendorMapping: {
                      carrierCode: values.carrierCode,
                      serviceCode: values.serviceCode,
                    }
                  }
                ]
              }
            ]
          };

          const updatedShippingMappings = [...facilityDetails.shippingMappings];

          if (shippingMapping && Object.keys(shippingMapping).length > 0) {
            // update existing mapping
            const index = updatedShippingMappings.findIndex(
              (mapping) => mapping.carrierName === shippingMapping.carrierName
            );
            updatedShippingMappings[index] = newMapping;
          } else {
            // add new mapping
            updatedShippingMappings.push(newMapping);
          }

          const updatedFacilityDetails = {
            ...facilityDetails,
            shippingMappings: updatedShippingMappings,
          };

          // dispatch the update action with the updated facility details
          dispatch(updateFacility({ vendorId, facilityId, data: updatedFacilityDetails, cb: backToShippingMapping }));
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleSubmit,
          submitCount,
          isSubmitting,
        }) => (
          <Card className={`shipping-mapping-card ${shippingMapping ? 'edit-mode' : ''}`}>
            <Card.Header>
              {Object.keys(shippingMapping).length > 0 && shippingMapping.carrierName ? 'Edit Shipping Mapping' : 'Add Shipping Mapping'}
              <div className="action-buttons">
                <Button
                  variant="secondary"
                  size="small"
                  onClick={backToShippingMapping}
                  label="Cancel"
                />
                <Button
                  variant="primary"
                  size="small"
                  label="Save"
                  disabled={isSubmitting}
                  onClick={() => (isSubmitting ? null : handleSubmit())}
                />
              </div>
            </Card.Header>
            <Card.Body>
              <form onSubmit={handleSubmit}>
                <div className="shipping-mapping-information">
                  <Input
                    label="Shipment Method"
                    name="shipmentMethod"
                    value={values.shipmentMethod}
                    onChange={handleChange}
                    placeholder="Shipment Method"
                    errorMessage={submitCount > 0 && errors.shipmentMethod}
                  />
                  <Input
                    label="Carrier Name"
                    name="carrierName"
                    value={values.carrierName}
                    onChange={handleChange}
                    placeholder="Carrier Name"
                    errorMessage={submitCount > 0 && errors.carrierName}
                  />
                  <Input
                    label="Services Method"
                    name="serviceMethodsAllowed"
                    value={values.serviceMethodsAllowed}
                    onChange={handleChange}
                    placeholder="Services Method"
                    errorMessage={submitCount > 0 && errors.serviceMethodsAllowed}
                  />
                  <Input
                    label="Carrier Code"
                    name="carrierCode"
                    value={values.carrierCode}
                    onChange={handleChange}
                    placeholder="Carrier Code"
                    errorMessage={submitCount > 0 && errors.carrierCode}
                  />
                  <Input
                    label="Service Code"
                    name="serviceCode"
                    value={values.serviceCode}
                    onChange={handleChange}
                    placeholder="Service Code"
                    errorMessage={submitCount > 0 && errors.serviceCode}
                  />
                  <Input
                    label="Country Codes to Apply"
                    name="countryCodesToApply"
                    value={values.countryCodesToApply}
                    onChange={handleChange}
                    placeholder="Country Codes to Apply"
                    errorMessage={submitCount > 0 && errors.countryCodesToApply}
                  />
                </div>
              </form>
            </Card.Body>
          </Card>
        )}
      </Formik>
    </div>
  );
};

export default AddEditShippingMapping;
