import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { TabbedMenu, Card, ButtonIcon, IconData, LoadingAnimation } from 'app/components';
import RefundView from './RefundView';
import RefundHistoryView from './RefundHistoryView';
import { orderDetailsSelector, orderDetailsLoadingSelector, orderDetailsErrorsSelector } from 'app/store/selectors/order';
import { getOrderDetails, reset as resetOrderData } from 'app/store/actions/order';
import { ArrowLeft, Upc } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import './index.scss';

const tabs = [
  {
    path: 'items',
    label: 'Items to Refund',
    content: RefundView,
  },
  {
    path: 'history',
    label: 'Refund History',
    content: RefundHistoryView,
  },
];

const Refund = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { orderId } = useParams();
  const orderDetails = useSelector(orderDetailsSelector);
  const orderDetailsLoading = useSelector(orderDetailsLoadingSelector);
  const orderDetailsErrors = useSelector(orderDetailsErrorsSelector);

  useEffect(() => {
    dispatch(getOrderDetails(orderId));

    return () => {
      dispatch(resetOrderData());
    };
  }, []);

  return (
    <div className="refund-details">
      {orderDetailsLoading && <LoadingAnimation />}
      {(orderDetails || orderDetailsErrors) && (
        <>
          <Card className="refund-details-header">
            <div className="order-num-container">
              <div className="order-num-group">
                <ButtonIcon icon={<ArrowLeft />} onClick={() => navigate(`/orders/${orderId}`)} />
                <div className="order-num">
                  Refund Order #{orderDetails?.shortId || orderId}
                </div>
              </div>
            </div>
            <div className="extra-details-container">
              {orderDetails?.externalId && (
                <IconData label={orderDetails.externalId} icon={<Upc />} />
              )}
            </div>
          </Card>
          <TabbedMenu tabs={tabs} orderId={orderId} orderDetails={orderDetails} orderDetailsErrors={orderDetailsErrors} />
        </>
      )}
    </div>
  );
}

export default Refund;
