import React from 'react';
import { DataPoint, Dropdown, Input, MultiSelect, ButtonIcon } from 'app/components'
import { PlusLg, Trash3 } from 'react-bootstrap-icons';
import { CountryList } from 'app/constants';
import './index.scss';

const Filter = props => {
  const {
    blockIndex,
    filterIndex,
    values,
    data,
    categories,
    editMode,
    addFilter,
    removeFilter,
    errors,
    handleChange,
    setFieldValue,
    validateForm,
    submitCount,
  } = props;

  const conditionOptions = [
    { value: '-', label: '-' },
    { value: 'ShipToCountry', label: 'Ships To Country' },
    { value: 'Quantity', label: 'Quantity' },
    { value: 'ProductCategory', label: 'Product Category' },
    { value: 'customField', label: 'Custom Field' }
  ];

  const getUniqueConditionOptions = () => {
    // get a list of all the conditions used by other filters in this block (excluding the current filter)
    // but allow the '-' option to be used multiple times
    const conditions = values.blocks[blockIndex].filterInfo.filters
      .filter((_, index) => index !== filterIndex)
      .map(filter => filter.condition)
      .filter(condition => condition !== '-');
    
    // now only return conditionOptions that are not in the conditions array
    return conditionOptions.filter(option => !conditions.includes(option.value));
  }

  const getLogicOptions = (condition) => {
    switch (condition) {
      case 'ShipToCountry':
        return [
          { value: 'is equal to', label: 'is equal to' },
        ];
      case 'Quantity':
        return [
          { value: 'is less than', label: 'is less than' },
          { value: 'is greater than', label: 'is greater than' },
          { value: 'is between', label: 'is between' },
        ];
      case 'ProductCategory':
        return [
          { value: 'is equal to', label: 'is equal to' },
        ];
      default:
        return [
          { value: 'is equal to', label: 'is equal to' },
          { value: 'contains', label: 'contains' },
        ];
    }
  }

  const determineThirdInputType = (data) => {
    if (data.condition === 'ShipToCountry') {
      if (editMode) return (
        <MultiSelect
          name={`blocks.${blockIndex}.filterInfo.filters.${filterIndex}.value`}
          label="Select Countries"
          options={CountryList.map(country => (
            { value: country.CountryCode, label: `${country.CountryCode} - ${country.Name}`}
          ))}
          value={data.value}
          readonly={!editMode}
          onChange={(e) => {
            const val = {
              target: {
                name: `blocks.${blockIndex}.filterInfo.filters.${filterIndex}.value`,
                value: e
              }
            };
            handleChange(val);
          }}
          errorMessage={ submitCount > 0 && errors.blocks?.[blockIndex]?.filterInfo?.filters?.[filterIndex]?.value && 'Select 1 or more Countries'}
        />
      );
      else return (
        <DataPoint
          title="Countries"
          maxWidth="350px"
          data={data.value.map(country => country.label).join(', ')}
        />
      );
    } else if (data.condition === 'ProductCategory') {
      if (editMode) return (
        <MultiSelect
          name={`blocks.${blockIndex}.filterInfo.filters.${filterIndex}.value`}
          options={categories.filter(category => category.id !== 'all').map(category => (
            { value: category.id, label: category.title }
          ))}
          value={data.value}
          readonly={!editMode}
          onChange={(e) => {
            const val = {
              target: {
                name: `blocks.${blockIndex}.filterInfo.filters.${filterIndex}.value`,
                value: e
              }
            };
            handleChange(val);
          }}
          label="Select Categories"
          errorMessage={ submitCount > 0 && errors.blocks?.[blockIndex]?.filterInfo?.filters?.[filterIndex]?.value && 'Select 1 or more Categories'}
        /> 
      )
      else return (
        <DataPoint
          title="Categories"
          maxWidth="350px"
          data={data.value.map(category => category.label).join(', ')}
        />
      );
    } else if (data.condition === 'Quantity') {
      if (editMode) {
        if (data.logic === 'is between') {
          return (
            <div className="quantity-between-inputs">
              <Input
                label="Greater Than or Equal"
                name={`blocks.${blockIndex}.filterInfo.filters.${filterIndex}.value`}
                value={data.value}
                readonly={!editMode}
                onChange={(e) => onlyNumbers(e)}
                placeholder="Greater Than or Equal"
                errorMessage={ submitCount > 0 && errors.blocks?.[blockIndex]?.filterInfo?.filters?.[filterIndex]?.value}
              />
              <Input
                label="Less Than or Equal"
                name={`blocks.${blockIndex}.filterInfo.filters.${filterIndex}.value2`}
                value={data.value2}
                readonly={!editMode}
                onChange={(e) => onlyNumbers(e)}
                placeholder="Less Than or Equal"
                errorMessage={ submitCount > 0 && errors.blocks?.[blockIndex]?.filterInfo?.filters?.[filterIndex]?.value2}
              />
            </div>
          )
        } else {
          return (
            <Input
              label={conditionSelected ? "Quantity" : ""}
              name={`blocks.${blockIndex}.filterInfo.filters.${filterIndex}.value`}
              value={data.value}
              readonly={!conditionSelected || !editMode}
              onChange={(e) => onlyNumbers(e)}
              placeholder={conditionSelected ? "Quantity" : ""}
              errorMessage={ submitCount > 0 && errors.blocks?.[blockIndex]?.filterInfo?.filters?.[filterIndex]?.value}
            />
          )
        }
      } else {
        if (data.logic === 'is between') {
          return (
            <DataPoint
              title="Quantity"
              data={`${data.value} - ${data.value2}`}
            />
          )
        } else {
          return (
            <DataPoint
              title="Quantity"
              data={data.value}
            />
          )
        }
      }
    } else {
      if (editMode) {
        if (data.logic === 'is between') {
          return (
            <div className="quantity-between-inputs">
              <Input
                label="Greater Than or Equal"
                name={`blocks.${blockIndex}.filterInfo.filters.${filterIndex}.value`}
                value={data.value}
                readonly={!editMode}
                onChange={(e) => onlyNumbers(e)}
                placeholder="Greater Than or Equal"
                errorMessage={ submitCount > 0 && errors.blocks?.[blockIndex]?.filterInfo?.filters?.[filterIndex]?.value}
              />
              <Input
                label="Less Than or Equal"
                name={`blocks.${blockIndex}.filterInfo.filters.${filterIndex}.value2`}
                value={data.value2}
                readonly={!editMode}
                onChange={(e) => onlyNumbers(e)}
                placeholder="Less Than or Equal"
                errorMessage={ submitCount > 0 && errors.blocks?.[blockIndex]?.filterInfo?.filters?.[filterIndex]?.value2}
              />
            </div>
          )
        } else {
          if (data.condition === '-') {
            return (
              <Input
                label="&#8203;"
                name={`blocks.${blockIndex}.filterInfo.filters.${filterIndex}.value`}
                value={data.value}
                disabled={true}
                onChange={handleChange}
                placeholder={conditionSelected ? "Value" : ""}
                errorMessage={ submitCount > 0 && errors.blocks?.[blockIndex]?.filterInfo?.filters?.[filterIndex]?.value}
              />
            )
          } else {
            return (
              <Input
                label={conditionSelected ? "Value" : "-"}
                name={`blocks.${blockIndex}.filterInfo.filters.${filterIndex}.value`}
                value={data.value}
                readonly={!conditionSelected || !editMode}
                onChange={handleChange}
                placeholder={conditionSelected ? "Value" : ""}
                errorMessage={ submitCount > 0 && errors.blocks?.[blockIndex]?.filterInfo?.filters?.[filterIndex]?.value}
              />
            )
          }
        }
      } else {
        if (data.logic === 'is between') {
          return (
            <DataPoint
              title="Value"
              data={`${data.value} - ${data.value2}`}
            />
          )
        } else {
          return (
            <DataPoint
              title="Value"
              data={data.value}
            />
          )
        }
      }
    }
  }

  const onlyNumbers = (event) => {
    const { name, value } = event.target;
    if (/^\d*$/.test(value)) {
      setFieldValue(name, value);
    }
  }

  const conditionSelected = data.condition !== '-';

  return (
    <div className={`block-filter ${editMode ? 'edit-mode' : ''}`}>
      <div className="column-1">
        {editMode && conditionOptions.some(option => option.value === data.condition) ? (
          <Dropdown
            label="Condition"
            name={`blocks.${blockIndex}.filterInfo.filters.${filterIndex}.condition`}
            value={values.blocks[blockIndex].filterInfo.filters[filterIndex].condition}
            onChange={(e) => {
              if (e.target.value === 'customField') {
                e.target.value = '';  
                setFieldValue(`blocks.${blockIndex}.filterInfo.filters.${filterIndex}.logic`, 'is equal to');
                setFieldValue(`blocks.${blockIndex}.filterInfo.filters.${filterIndex}.value`, '');
                setFieldValue(`blocks.${blockIndex}.filterInfo.filters.${filterIndex}.value2`, '');
              } else if (e.target.value === 'ShipToCountry' || e.target.value === 'ProductCategory') {
                setFieldValue(`blocks.${blockIndex}.filterInfo.filters.${filterIndex}.logic`, 'is equal to');
                setFieldValue(`blocks.${blockIndex}.filterInfo.filters.${filterIndex}.value`, []);
                setFieldValue(`blocks.${blockIndex}.filterInfo.filters.${filterIndex}.value2`, '');
              } else if (e.target.value === 'Quantity') {
                setFieldValue(`blocks.${blockIndex}.filterInfo.filters.${filterIndex}.logic`, 'is less than');
                setFieldValue(`blocks.${blockIndex}.filterInfo.filters.${filterIndex}.value`, '');
                setFieldValue(`blocks.${blockIndex}.filterInfo.filters.${filterIndex}.value2`, '');
              }
              handleChange(e);
                // updating the formik state and immediately validating the form causes it to miss the change.  Wait till the next render to validate.
                setTimeout(() => {
                  validateForm();
                }, 1);
            }}
            readonly={!editMode}
            options={getUniqueConditionOptions()}
            errorMessage={ submitCount > 0 && errors.blocks?.[blockIndex]?.filterInfo?.filters?.[filterIndex]?.condition}
          />
        ) : (
          editMode && (
            <Input
              label="Condition"
              name={`blocks.${blockIndex}.filterInfo.filters.${filterIndex}.condition`}
              value={values.blocks[blockIndex].filterInfo.filters[filterIndex].condition}
              onChange={handleChange}
              readonly={!editMode}
              placeholder="Condition"
              errorMessage={submitCount > 0 && errors.blocks?.[blockIndex]?.filterInfo?.filters?.[filterIndex]?.condition}
            />
          )
        )}
        {!editMode && (
          <DataPoint
            title="Condition"
            data={data.condition}
          />
        )}
      </div>
      <div className="column-2">
        {editMode ? (
          <Dropdown
            label="Logic"
            name={`blocks.${blockIndex}.filterInfo.filters.${filterIndex}.logic`}
            value={values.blocks[blockIndex].filterInfo.filters[filterIndex].logic}
            disabled={!conditionSelected}
            className="filter-logic"
            readonly={!editMode}
            onChange={handleChange}
            options={data.condition === '-' ? [] : getLogicOptions(data.condition)}
            errorMessage={ submitCount > 0 && errors.blocks?.[blockIndex]?.filterInfo?.filters?.[filterIndex]?.logic}
          /> ) : (
            <DataPoint
              title="Logic"
              enableClipboardCopy={false}
              data={data.logic}
            />
          )
        }
      </div>
      <div className="column-3">
        {determineThirdInputType(data)}
      </div>
      {editMode && (
        <div className="row-options">
          <ButtonIcon
            icon={<PlusLg />}
            onClick={() => addFilter()}
          />
          <ButtonIcon
            icon={<Trash3 />}
            destructive={true}
            onClick={() => removeFilter(filterIndex)}
          />
        </div>
      )}
    </div>
  )
}

export default Filter;