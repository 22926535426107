import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { getHistory } from 'app/store/actions/history';
import { getShipmentsByOrderId, getAuditInfo as getShipmentAuditInfo } from 'app/store/actions/shipment';
import { getAuditInfo as getOrderAuditInfo } from 'app/store/actions/order';
import { getMessagesAuditInfo as getMessagesAuditInfo } from 'app/store/actions/notification';
import { historyDataSelector, historyLoadingSelector, historyErrorsSelector } from 'app/store/selectors/history';
import { shipmentsSelector, shipmentsLoadingSelector, auditInfoLoadingSelector as shipmentAuditInfoLoadingSelector } from 'app/store/selectors/shipment';
import { auditInfoLoadingSelector as orderAuditInfoLoadingSelector } from 'app/store/selectors/order';
import { auditInfoLoadingSelector as messagesAuditInfoLoadingSelector } from 'app/store/selectors/notification';
import { Table, TableHeader, Card, Dropdown, LoadingAnimation, Pagination, AuditInfoModal, Link } from 'app/components';
import moment from 'moment';
import './index.scss';

const HistoryView = (props) => {
  const { orderId } = props;
  const dispatch = useDispatch();

  const [auditInfo, setAuditInfo] = useState(null);

  const historyData = useSelector(historyDataSelector);
  const historyDataLoading = useSelector(historyLoadingSelector);
  const historyDataError = useSelector(historyErrorsSelector);
  const shipmentsLoading = useSelector(shipmentsLoadingSelector);
  const shipments = useSelector(shipmentsSelector);
  const shipmentAuditInfoLoading = useSelector(shipmentAuditInfoLoadingSelector);
  const orderAuditInfoLoading = useSelector(orderAuditInfoLoadingSelector);
  const messagesAuditInfoLoading = useSelector(messagesAuditInfoLoadingSelector);

  const [searchParams, setSearchParams] = useSearchParams();
  const entityType = searchParams.get('entityType') || '';
  const currentPage = parseInt(searchParams.get('page') || '1', 10);
  const pageSize = parseInt(searchParams.get('pageSize') || '50', 10);
  const sortDir = searchParams.get('sortDir') || 'desc';
  const entityTypes = [{ "Name": "Order", "Value": "Order" }, { "Name": "Order Item", "Value": "OrderItem" }, { "Name": "Shipment", "Value": "Shipment" }, { "Name": "Package", "Value": "Package" }, { "Name": "Routing", "Value": "Routing" }, { "Name": "Inventory", "Value": "Inventory" }, { "Name": "Note", "Value": "Note" }];

  useEffect(() => {
    dispatch(getShipmentsByOrderId(orderId));
  }, []);

  useEffect(() => {
    dispatch(getHistory({ parentType: 'Order', parentId: orderId, entityType, page: currentPage, pageSize: pageSize, sortAscending: sortDir }));
  }, [entityType, currentPage, pageSize, sortDir, dispatch]);

  const onRowClick = (history) => {
    switch (history.entityType) {
      case 'Shipment': dispatch(getShipmentAuditInfo({ auditUrl: history.auditUrl, cb: (auditInfo) => onAuditInfoReceived(auditInfo) })); break;
      case 'Order': dispatch(getOrderAuditInfo({ auditUrl: history.auditUrl, cb: (auditInfo) => onAuditInfoReceived(auditInfo) })); break;
      case 'Message': dispatch(getMessagesAuditInfo({ auditUrl: history.auditUrl, cb: (auditInfo) => onAuditInfoReceived(auditInfo) })); break;
      default: break;
    }
  };

  const onAuditInfoReceived = (auditInfo) => {
    setAuditInfo(auditInfo?.events || []);
  }

  return (
    <Card className="history-view">
      <Card.Header>
        History Log Details
        <Dropdown
          name="entityType"
          className="entity-type-dropdown"
          allowClear={true}
          showErrorMessages={false}
          options={entityTypes.map(entityType => (
            { value: entityType.Value, label: entityType.Name }
          ))}
          placeholder="Filter by Entity Type"
          value={entityType}
          onChange={(e) => {
            const updatedSearchParams = new URLSearchParams(searchParams.toString());
            updatedSearchParams.set('entityType', e.target.value);
            setSearchParams(updatedSearchParams.toString());
          }}
        />
      </Card.Header>
      {(historyDataLoading || shipmentsLoading || shipmentAuditInfoLoading || orderAuditInfoLoading || messagesAuditInfoLoading) && <LoadingAnimation />}
      {historyData && shipments && (
        <Card.Body>
          <Table size="small" verticalAlign="top" highlightRows={false}>
            <TableHeader
              sortBy="timestamp"
              sortDir={sortDir}
              options={[
                { id: 'timestamp', label: 'Timestamp', orderable: true },
                { id: 'user', label: 'User' },
                { id: 'source', label: 'Source' },
                { id: 'event', label: 'Event' },
                { id: 'message', label: 'Message', width: "30%" },
                { id: 'action', label: 'Action', width: "105px" },
              ]}
            />
            <tbody className="table-body">
              {historyData.historyLogs.map((history) => (
                <tr key={history.id}>
                  <td>{moment(history.dateCreated).local().format('MMM DD, YYYY h:mm a')}</td>
                  <td>{history.userName}</td>
                  <td>{history.entityType}</td>
                  <td>{history.event}</td>
                  <td className="history-description">{history.description}</td>
                  <td>
                    {['Order', 'Shipment', 'Message'].includes(history.entityType) ? (
                      <Link
                        label="View Log"
                        onClick={() => {
                          onRowClick(history)
                        }}
                      />
                    ) : null}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Pagination totalItems={historyData?.total || 0} />
        </Card.Body>
      )}
      {historyData && historyData.historyLogs.length === 0 && (
        <Card.Body>
          <div className="history-data-empty">
            No history data found.
          </div>
        </Card.Body>
      )}
      {historyDataError && (
        <Card.Body>
          <div className="history-data-failed">
            History Data failed to load.  Refresh the page to try again.
          </div>
        </Card.Body>
      )}
      {auditInfo !== null && (
        <AuditInfoModal
          title="Audit Log Details"
          auditInfo={auditInfo}
          onClose={() => setAuditInfo(null)}
        />
      )}
    </Card>
  );
};

export default HistoryView;
