import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getCategories, getAllProducts, getProductVariants } from 'app/store/actions/catalog';
import { vendorDetailsSelector, vendorDetailsLoadingSelector, addUpdateProductsLoadingSelector, addUpdateProductsErrorsSelector } from 'app/store/selectors/vendor';
import { addProductsToVendor, clearErrors } from 'app/store/actions/vendor';
import {
  categoriesDataSelector,
  categoriesLoadingSelector,
  categoriesErrorsSelector,
  productsDataSelector,
  productsLoadingSelector,
  productsErrorsSelector,
  productVariantsSelector,
} from 'app/store/selectors/catalog';
import { Modal, ProgressBar } from 'react-bootstrap';
import { Card, LoadingAnimation, Button, MessageBar } from 'app/components';
import ProductSelector from './ProductSelector';
import SelectedProducts from './SelectedProducts';
import SearchBar from 'app/components/SearchBar';
import { convertJsonDataToApiFormat, downloadAllProductsXLSX, parseAxiosError } from '../../../utils';
import * as XLSX from 'xlsx';
import './index.scss';

const AddProducts = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { vendorId } = useParams();

  const [searchString, setSearchString] = useState('');
  const [numVarientsDownloaded, setNumVariantsDownloaded] = useState('');
  const [currentlySelectedProducts, setCurrentlySelectedProducts] = useState([]);
  const [numVarientsDownloading, setNumVariantsDownloading] = useState(0);
  const [failedProductUploads, setFailedProductUploads] = useState(null);
  const [originalJsonData, setOriginalJsonData] = useState(null); // Store the original uploaded JSON data

  const vendorDetails = useSelector(vendorDetailsSelector);
  const vendorDetailsLoading = useSelector(vendorDetailsLoadingSelector);
  const categories = useSelector(categoriesDataSelector);
  const categoriesLoading = useSelector(categoriesLoadingSelector);
  const categoriesErrors = useSelector(categoriesErrorsSelector);
  const products = useSelector(productsDataSelector);
  const productsLoading = useSelector(productsLoadingSelector);
  const productsErrors = useSelector(productsErrorsSelector);
  const productVariants = useSelector(productVariantsSelector);
  const addUpdateProductsLoading = useSelector(addUpdateProductsLoadingSelector);
  const addUpdateProductsErrors = useSelector(addUpdateProductsErrorsSelector);

  useEffect(() => {
    dispatch(clearErrors());

    // if they have not passed in all the correct info, just forward them back to the vendors page
    if (!vendorDetails || vendorDetails.vendorId !== vendorId) {
      navigate('/admin/vendors');
    }
  }, []);

  useEffect(() => {
    dispatch(getCategories());
    dispatch(getAllProducts());
  }, []);

  function getProductTitleById(id) {
    // lookup the product name by product id
    const foundObject = products.find(obj => obj.id === id);
    return foundObject ? foundObject.title.slice(0, 30) : null;
  }

  useEffect(() => {
    // have they completed downloading all the product variants?
    if (numVarientsDownloaded === numVarientsDownloading) {
      // create a workbook to store all the data
      const workbook = XLSX.utils.book_new();

      // the list of product variants in redux contains all the product varients we have previous retrieved data for.  This list may 
      // include product variants they didn't select for this csv download.  Process only the product variants they have currently selected.
      for (const id of currentlySelectedProducts) {
        // is this item one of the products they selected?
        if (Object.prototype.hasOwnProperty.call(productVariants, id)) {
          // every product type will be stored on a different sheet/tab in the workbook
          const sheet = [];
          productVariants[id].forEach(function (item) {
            const obj = {
              "Variant Id": item.id,
              "SKU": item.sku,
              "Vendor SKU": "",
              "Facility IDs": "All",
              "Description": item.description,
              "Production Time (in days)": item.productionTime,
              "Product Cost": "",
              "Currency": item.price.currency
            }

            for (let i = 0; i < item.decorations.length; i++) {
              obj["DecorationCost_" + (i + 1)] = "";
              obj["Area_" + (i + 1)] = item.decorations[i].area;
              obj["VendorArea_" + (i + 1)] = "Default";
              obj["DPI_" + (i + 1)] = item.decorations[i].dpi;
              obj["Height_" + (i + 1)] = item.decorations[i].height;
              obj["Width_" + (i + 1)] = item.decorations[i].width;
              obj["ImageFormat_" + (i + 1)] = item.decorations[i].format;
              obj["PrintMethod_" + (i + 1)] = item.decorations[i].printMethod;
            }

            sheet.push(obj);
          });
          // convert the json to a worksheet
          const worksheet = XLSX.utils.json_to_sheet(sheet);

          // and add the worksheet to the workbook
          XLSX.utils.book_append_sheet(workbook, worksheet, getProductTitleById(id));
        }
      }

      // the workbook has been generated.  Prepare it for download by converting it to a blob
      const workbookBlob = new Blob([XLSX.write(workbook, { type: 'buffer' })], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });

      // Create a download link
      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(workbookBlob);
      downloadLink.download = 'ProductVariants.xlsx';

      // Simulate a click on the download link
      downloadLink.click();

      // setting the number of variants currently downloading to zero will close the download modal
      setNumVariantsDownloading(0);
    }
  }, [numVarientsDownloaded, numVarientsDownloading]);

  const downloadProductCSV = () => {
    // reset the number of variants we have downloaded to zero
    setNumVariantsDownloaded(0);
    // and store how many we are about to download
    setNumVariantsDownloading(currentlySelectedProducts.length)
    // loop through all the items and download them asyncronously
    currentlySelectedProducts.forEach((product) => {
      dispatch(getProductVariants({ productId: product, currentPage: 1, pageSize: 250, cb: onProductVariantDataLoaded }));
    });
  }

  const onProductVariantDataLoaded = () => {
    // one of the variants have finished downloading
    setNumVariantsDownloaded(prevNumVariantsDownloaded => prevNumVariantsDownloaded + 1);
  }

  const onUploadCSVButtonPressed = () => {
    const inputElement = document.createElement('input');
    inputElement.type = 'file';
    inputElement.accept = '.xls,.xlsx';
    inputElement.onchange = handleFileUpload;
    inputElement.click();
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });

      const sheetNames = workbook.SheetNames;
      const jsonData = {};

      sheetNames.forEach((sheetName) => {
        const worksheet = workbook.Sheets[sheetName];
        const sheetData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        jsonData[sheetName] = sheetData;
      });

      const apiFormat = convertJsonDataToApiFormat(jsonData, vendorDetails);
      
      // store the original JSON data
      setOriginalJsonData(apiFormat);

      dispatch(addProductsToVendor({ data: apiFormat, cb: onProductsSuccessfullyAdded }));
    };

    reader.readAsArrayBuffer(file);
  };

  const onProductsSuccessfullyAdded = (resp) => {
    let successCount = 0;
    let failureCount = 0;

    if (resp.bulk && Array.isArray(resp.bulk)) {
      resp.bulk.forEach(item => {
        if (item.status === 200) {
          successCount++;
        } else {
          failureCount++;
        }
      });
    }

    const data = {
      successCount,
      failureCount,
      total: resp.bulk.length,
      resp,
    };

    if (failureCount === 0) {
      navigate(`/admin/vendors/${vendorDetails.vendorId}/products`);
    } else {
      setFailedProductUploads(data);
    }
  }

  return (
    <div className="vendor-add-products">
      {failedProductUploads && (
        <MessageBar
          className="upload-products-error"
          onClick={() => {
            // make a copy of the original JSON data
            const updatedFailedProducts = JSON.parse(JSON.stringify(originalJsonData));

            for (let i = 0; i < failedProductUploads.resp.bulk.length; i++) {
              const item = failedProductUploads.resp.bulk[i];
              if (item.status !== 200) {
                console.log('Failed Product', originalJsonData.bulk[i]);
                updatedFailedProducts.bulk[i].failedReason = item.response.errors[0].errorMessage;
              } else{
                updatedFailedProducts.bulk[i] = null;
              }
            }

            // now remove all the null entries
            updatedFailedProducts.bulk = updatedFailedProducts.bulk.filter(item => item !== null);

            downloadAllProductsXLSX(updatedFailedProducts.bulk);
          }}
        >
          <div>{`${failedProductUploads.total - failedProductUploads.failureCount} / ${failedProductUploads.total} Products Successfully Added`}</div>
          <div className="additional-message">Click to Download Failed Products</div>
        </MessageBar>
      )}
      {addUpdateProductsErrors && <MessageBar className="upload-products-error" message={parseAxiosError(addUpdateProductsErrors)} color="red" />}
      <Card>
        {(categoriesLoading || productsLoading || vendorDetailsLoading || addUpdateProductsLoading) && <LoadingAnimation />}
        <Card.Header hideDivider>
          Add Products
          <div className="header-buttons">
            <Button
              variant="secondary"
              size="small"
              label="Cancel"
              disabled={categoriesLoading || productsLoading || vendorDetailsLoading || productsErrors || categoriesErrors}
              onClick={() => navigate(`products`)}
            />
            <Button
              variant="primary"
              size="small"
              label="Upload Product XLSX"
              disabled={categoriesLoading || productsLoading || vendorDetailsLoading || productsErrors || categoriesErrors}
              onClick={() => onUploadCSVButtonPressed()}
            />
          </div>
        </Card.Header>
        {categories && products && vendorDetails && !productsErrors && !categoriesErrors && (
          <Card.Body>
            <div className="left-panel">
              <SearchBar
                searchPlaceholderText="Search for a Product"
                onSearchStringUpdated={setSearchString}
              />
              <ProductSelector
                products={products}
                categories={categories}
                searchString={searchString}
                onProductSelected={(id) => setCurrentlySelectedProducts([...currentlySelectedProducts, id])}
                currentlySelectedProducts={currentlySelectedProducts}
              />
            </div>
            <div className="right-panel">
              <div className="selected-products-header">
                Selected Products
                <Button
                  variant="secondary"
                  size="small"
                  label="Download XLSX"
                  disabled={currentlySelectedProducts.length === 0}
                  onClick={() => downloadProductCSV()}
                />
              </div>
              <SelectedProducts
                products={products}
                categories={categories}
                currentlySelectedProducts={currentlySelectedProducts}
                onRemoveSelectedProduct={(id) => setCurrentlySelectedProducts(currentlySelectedProducts.filter((productId) => productId !== id))}
              />
            </div>
          </Card.Body>
        )}
        {(productsErrors || categoriesErrors) && (
          <MessageBar color="yellow" className="mt-3">
            An error occurred while fetching data
          </MessageBar>
        )}
        <Modal show={numVarientsDownloading > 0}>
          <Modal.Header>
            <Modal.Title>Creating Product XLSX</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ProgressBar
              animated
              now={numVarientsDownloaded}
              max={numVarientsDownloading}
            />
          </Modal.Body>
        </Modal>
      </Card>
    </div>
  )
}

export default AddProducts;
