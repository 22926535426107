import React, { useMemo } from 'react';
import { Card, MessageBar } from 'app/components';
import ReactApexChart from 'react-apexcharts';
import Skeleton from 'react-loading-skeleton';
import { Check2Square } from 'react-bootstrap-icons';

const LateOrdersInDays = ({ queryData = {} }) => {
  const { data, loading, error } = queryData;

  const categories = useMemo(() => {
    const now = new Date();
    const result = {
      '2-3 days': 0,
      '3-4 days': 0,
      '4-5 days': 0,
      '5-6 days': 0,
      '6-7 days': 0,
      '7+ days': 0
    };

    data && data.length > 0 && data.forEach(shipment => {
      const productionDate = new Date(shipment.in_production_at);
      const timeDiff = now - productionDate;
      const daysOld = Math.floor(timeDiff / (1000 * 60 * 60 * 24)); // Convert milliseconds to days

      if (daysOld >= 2 && daysOld < 3) {
        result['2-3 days']++;
      } else if (daysOld >= 3 && daysOld < 4) {
        result['3-4 days']++;
      } else if (daysOld >= 4 && daysOld < 5) {
        result['4-5 days']++;
      } else if (daysOld >= 5 && daysOld < 6) {
        result['5-6 days']++;
      } else if (daysOld >= 6 && daysOld < 7) {
        result['6-7 days']++;
      } else if (daysOld >= 7) {
        result['7+ days']++;
      }
    });

    return result;
  }, [data]);

  const counts = Object.values(categories);
  const minValue = Math.max(0, Math.floor(Math.min(...counts) * 0.9));
  const maxValue = Math.ceil(Math.max(...counts) * 1.1);

  const chartData = useMemo(() => ({
    series: [{
      data: [
        categories['2-3 days'],
        categories['3-4 days'],
        categories['4-5 days'],
        categories['5-6 days'],
        categories['6-7 days'],
        categories['7+ days']
      ]
    }],
    options: {
      title: {
        text: 'Late Orders',
        align: 'left',
        style: {
          fontFamily: "Source Sans Pro",
          fontSize: '16px',
          fontWeight: 600,
          lineHeight: '26px',
          color: '#2E2E2E'
        }
      },
      chart: {
        type: 'bar',
        animations: {
          enabled: false,
        },
        events: {
          click: function (chart, w, e) {
            console.log(chart, w, e)
          }
        }
      },
      colors: ['#9CB4E2', '#F8B05B', '#E8D953', '#6DCB5D', '#9AA4FF'],
      plotOptions: {
        bar: {
          columnWidth: '45%',
          distributed: true,
          dataLabels: {
            position: 'top'
          }
        }
      },
      dataLabels: {
        enabled: true,
        offsetY: -20,
        style: {
          fontSize: '12px',
          colors: ['#878787']
        }
      },
      legend: {
        show: false
      },
      xaxis: {
        categories: ['2-3 days', '3-4 days', '4-5 days', '5-6 Days', '6-7 days', '7+ days'],
        labels: {
          style: {
            colors: ['#878787'],
            fontSize: '12px'
          }
        }
      },
      yaxis: {
        min: minValue,
        max: maxValue,
        labels: {
          style: {
            colors: ['#878787'],
            fontSize: '12px'
          },
          formatter: function (val) {
            return Math.floor(val);
          }
        },
        // tickAmount: maxValue - minValue,
      },
    },
  }), [categories, minValue, maxValue]);

  return (
    <Card className={`late-orders-in-days gooten-dashboard-chart ${data && data.length > 0 ? 'has-data' : ''}`}>
      {data && data.length > 0 && (
        <ReactApexChart options={chartData.options} series={chartData.series} type="bar" height={350} />
      )}
      {!loading && !error && data && data.length === 0 && (
        <div className="loading">
          <div className="loading-header">
            {chartData.options.title.text}
          </div>
          <MessageBar color="blue" icon={<Check2Square />}>
            No late orders
          </MessageBar>
        </div>
      )}
      {error && (
        <div className="loading">
          <div className="loading-header">
            {chartData.options.title.text}
          </div>
          <MessageBar color="yellow">
            An error occurred while fetching data
          </MessageBar>
        </div>
      )}
      {loading && (
        <div className="loading">
          <div className="loading-header">
            {chartData.options.title.text}
          </div>
          <Skeleton height={150} count={2} borderRadius={15} />
        </div>
      )}
    </Card>
  )
}

export default LateOrdersInDays;
