import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Person, People, ThreeDotsVertical } from 'react-bootstrap-icons';
import { ButtonIcon } from 'app/components';
import { deleteNote, toggleEditNote } from 'app/store/actions/note';
import PropTypes from 'prop-types';
import { currentUserSelector } from 'app/store/selectors/user';

const Note = ({ note }) => {
    const dispatch = useDispatch();
    const currentUser = useSelector(currentUserSelector);

    const handleDeleteNote = () => {
        dispatch(deleteNote(note.id));
    };

    const handleEditNote = () => {
        dispatch(toggleEditNote({ id: note.id }));
    };

    return (

        <div className="note-row-right">
            <div className="note-header">
                <div className="note-header-info">
                    <div className="note-row-date">{(note.updatedAt ? 'Last Edited: ' : '') + moment(note.updatedAt || note.createdAt).local().format('MMM DD, YYYY h:mm a')}</div>
                    <div className='note-author-container'>
                        <span className="note-author">{note.createdBy?.userName}</span>
                        {currentUser?.id === note?.createdBy?.userId && (
                            <ButtonIcon icon={<ThreeDotsVertical />} options={[
                                { value: 'Edit', label: 'Edit', onClick: handleEditNote },
                                { value: 'Delete', label: 'Delete', destructive: true, onClick: handleDeleteNote }
                            ]} />
                        )}
                    </div>
                    <span className="note-type">
                        {note.isPrivate ? <Person /> : <People />}
                        {note.isPrivate ? ' Private Note' : ' Public Note'}
                    </span>
                </div>
            </div>
            <div className="note-row-description" dangerouslySetInnerHTML={{ __html: note.content }} />
        </div>
    );
};

Note.propTypes = {
    note: PropTypes.shape({
        id: PropTypes.string.isRequired,
        content: PropTypes.string.isRequired,
        updatedAt: PropTypes.string,
        createdAt: PropTypes.string.isRequired,
        createdBy: PropTypes.shape({
            userId: PropTypes.string.isRequired,
            userName: PropTypes.string.isRequired
        }),
        isPrivate: PropTypes.bool.isRequired
    }).isRequired
};

export default Note;
