import { createAction } from 'redux-actions';

const prefix = "USER";

export const getAccessToken = createAction(`${prefix}/GET_ACCESS_TOKEN`);

export const signinUser = createAction(`${prefix}/SIGNIN_USER`);
export const signinUserSuccess = createAction(`${prefix}/SIGNIN_USER_SUCCESS`);
export const signinUserError = createAction(`${prefix}/SIGNIN_USER_ERROR`);

export const logoutUser = createAction(`${prefix}/LOGOUT_USER`);

export const getUsers = createAction(`${prefix}/GET_USERS`);
export const getUsersSuccess = createAction(`${prefix}/GET_USERS_SUCCESS`);
export const getUsersFailure = createAction(`${prefix}/GET_USERS_FAILURE`);

export const getUserDetails = createAction(`${prefix}/GET_USER_DETAILS`);
export const getUserDetailsSuccess = createAction(`${prefix}/GET_USER_DETAILS_SUCCESS`);
export const getUserDetailsError = createAction(`${prefix}/GET_USER_DETAILS_ERROR`);

export const createUser = createAction(`${prefix}/CREATE_USER`);
export const createUserSuccess = createAction(`${prefix}/CREATE_USER_SUCCESS`);
export const createUserFailure = createAction(`${prefix}/CREATE_USER_FAILURE`);

export const updateUser = createAction(`${prefix}/UPDATE_USER`);
export const updateUserSuccess = createAction(`${prefix}/UPDATE_USER_SUCCESS`);
export const updateUserFailure = createAction(`${prefix}/UPDATE_USER_FAILURE`);

export const resetPassword = createAction(`${prefix}/RESET_PASSWORD`);
export const resetPasswordSuccess = createAction(`${prefix}/RESET_PASSWORD_SUCCESS`);
export const resetPasswordFailure = createAction(`${prefix}/RESET_PASSWORD_FAILURE`);

export const requestForgotPassword = createAction(`${prefix}/FORGOT_PASSWORD`);
export const requestForgotPasswordSuccess = createAction(`${prefix}/FORGOT_PASSWORD_SUCCESS`);
export const requestForgotPasswordFailure = createAction(`${prefix}/FORGOT_PASSWORD_FAILURE`);

export const updateForgotPassword = createAction(`${prefix}/UPDATE_FORGOT_PASSWORD`);
export const updateForgotPasswordSuccess = createAction(`${prefix}/UPDATE_FORGOT_PASSWORD_SUCCESS`);
export const updateForgotPasswordFailure = createAction(`${prefix}/UPDATE_FORGOT_PASSWORD_FAILURE`);

export const getRoles = createAction(`${prefix}/GET_ROLES`);
export const getRolesSuccess = createAction(`${prefix}/GET_ROLES_SUCCESS`);
export const getRolesFailure = createAction(`${prefix}/GET_ROLES_FAILURE`);

export const updateUserStatus = createAction(`${prefix}/UPDATE_USER_STATUS`);
export const updateUserStatusSuccess = createAction(`${prefix}/UPDATE_USER_STATUS_SUCCESS`);
export const updateUserStatusFailure = createAction(`${prefix}/UPDATE_USER_STATUS_FAILURE`);

export const deleteUser = createAction(`${prefix}/DELETE_USER`);
export const deleteUserSuccess = createAction(`${prefix}/DELETE_USER_SUCCESS`);
export const deleteUserFailure = createAction(`${prefix}/DELETE_USER_FAILURE`);

export const getRolesWithPermissions = createAction(`${prefix}/GET_ROLES_WITH_PERMISSIONS`);
export const getRolesWithPermissionsSuccess = createAction(`${prefix}/GET_ROLES_WITH_PERMISSIONS_SUCCESS`);
export const getRolesWithPermissionsError = createAction(`${prefix}/GET_ROLE_WITHS_PERMISSIONS_ERROR`);

export const assignRoleToUser = createAction(`${prefix}/ASSIGN_ROLE_TO_USER`);
export const assignRoleToUserSuccess = createAction(`${prefix}/ASSIGN_ROLE_TO_USER_SUCCESS`);
export const assignRoleToUserError = createAction(`${prefix}/ASSIGN_ROLE_TO_USER_ERROR`);

export const updateRolePermissionsToUser = createAction(`${prefix}/UPDATE_ROLE_PERMISSIONS_TO_USER`);
export const updateRolePermissionsToUserSuccess = createAction(`${prefix}/UPDATE_ROLE_PERMISSIONS_TO_USER_SUCCESS`);
export const updateRolePermissionsToUserError = createAction(`${prefix}/UPDATE_ROLE_PERMISSIONS_TO_USER_ERROR`);

export const deleteRole = createAction(`${prefix}/DELETE_ROLE`);
export const deleteRoleSuccess = createAction(`${prefix}/DELETE_ROLE_SUCCESS`);
export const deleteRoleFailure = createAction(`${prefix}/DELETE_ROLE_FAILURE`);

export const getRoleDetails = createAction(`${prefix}/GET_ROLE_DETAILS`);
export const getRoleDetailsSuccess = createAction(`${prefix}/GET_ROLE_DETAILS_SUCCESS`);
export const getRoleDetailsError = createAction(`${prefix}/GET_ROLE_DETAILS_ERROR`);

export const createRole = createAction(`${prefix}/CREATE_ROLE`);
export const createRoleSuccess = createAction(`${prefix}/CREATE_ROLE_SUCCESS`);
export const createRoleFailure = createAction(`${prefix}/CREATE_ROLE_FAILURE`);

export const updateRole = createAction(`${prefix}/UPDATE_ROLE`);
export const updateRoleSuccess = createAction(`${prefix}/UPDATE_ROLE_SUCCESS`);
export const updateRoleFailure = createAction(`${prefix}/UPDATE_ROLE_FAILURE`);

export const getResources = createAction(`${prefix}/GET_RESOURCES`);
export const getResourcesSuccess = createAction(`${prefix}/GET_RESOURCES_SUCCESS`);
export const getResourcesFailure = createAction(`${prefix}/GET_RESOURCES_FAILURE`);

export const searchRoles = createAction(`${prefix}/SEARCH_ROLES`);
export const searchRolesSuccess = createAction(`${prefix}/SEARCH_ROLES_SUCCESS`);
export const searchRolesFailure = createAction(`${prefix}/SEARCH_ROLES_FAILURE`);

export const updateRoleName = createAction(`${prefix}/UPDATE_ROLE_NAME`);
export const updateRoleNameSuccess = createAction(`${prefix}/UPDATE_ROLE_NAME_SUCCESS`);
export const updateRoleNameFailure = createAction(`${prefix}/UPDATE_ROLE_NAME_FAILURE`);

export const getGroups = createAction(`${prefix}/GET_GROUPS`);
export const getGroupsSuccess = createAction(`${prefix}/GET_GROUPS_SUCCESS`);
export const getGroupsFailure = createAction(`${prefix}/GET_GROUPS_FAILURE`);
export const setSelectedGroup = createAction(`${prefix}/SET_SELECTED_GROUP`);

export const addAttributesToUser = createAction(`${prefix}/ADD_ATTRIBUTES_USER`);
export const addAttributesToUserSuccess = createAction(`${prefix}/ADD_ATTRIBUTES_USER_SUCCESS`);
export const addAttributesToUserFailure = createAction(`${prefix}/ADD_ATTRIBUTES_USER_FAILURE`);

export const removeAttributesToUser = createAction(`${prefix}/REMOVE_ATTRIBUTES_USER`);
export const removeAttributesToUserSuccess = createAction(`${prefix}/REMOVE_ATTRIBUTES_USER_SUCCESS`);
export const removeAttributesToUserFailure = createAction(`${prefix}/REMOVE_ATTRIBUTES_USER_FAILURE`);

export const setViewAs = createAction(`${prefix}/SET_VIEW_AS`);
export const setViewAsSuccess = createAction(`${prefix}/SET_VIEW_AS_SUCCESS`);

export const setPermissions = createAction(`${prefix}/SET_PERMISSIONS`);
export const updateTokens = createAction(`${prefix}/UPDATE_TOKENS`);

export const getUserSchema = createAction(`${prefix}/GET_USER_SCHEMA`);
export const getUserSchemaSuccess = createAction(`${prefix}/GET_USER_SCHEMA_SUCCESS`);
export const getUserSchemaFailure = createAction(`${prefix}/GET_USER_SCHEMA_FAILURE`);

export const getUserConfiguration = createAction(`${prefix}/GET_USER_CONFIGURATION`);
export const getUserConfigurationSuccess = createAction(`${prefix}/GET_USER_CONFIGURATION_SUCCESS`);
export const getUserConfigurationFailure = createAction(`${prefix}/GET_USER_CONFIGURATION_FAILURE`);

export const updateUserConfiguration = createAction(`${prefix}/UPDATE_USER_CONFIGURATION`);
export const updateUserConfigurationSuccess = createAction(`${prefix}/UPDATE_USER_CONFIGURATION_SUCCESS`);
export const updateUserConfigurationFailure = createAction(`${prefix}/UPDATE_USER_CONFIGURATION_FAILURE`);

export const clearErrors = createAction(`${prefix}/CLEAR_ERRORS`);
export const reset = createAction(`${prefix}/RESET`);

export const RESET_ALL = "RESET_ALL";