import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { facilityDetailsSelector, facilityDetailsErrorsSelector } from 'app/store/selectors/vendor';
import { Button, Card, Table, TableHeader, ButtonIcon, Modal } from 'app/components';
import { ExclamationCircle, ThreeDotsVertical } from 'react-bootstrap-icons';
import AddEditShippingMapping from './AddEditShippingMapping';
import { updateFacility } from 'app/store/actions/vendor'; // Import your update action
import './index.scss';

const ShippingMappingView = (props) => {
  const { vendorId, facilityId } = props;
  const facilityDetails = useSelector(facilityDetailsSelector);
  const facilityDetailsErrorsFound = useSelector(facilityDetailsErrorsSelector);
  const dispatch = useDispatch();

  const [editingMapping, setEditingMapping] = useState(null);
  const [deleteMappingModal, showDeleteMappingModal] = useState(false);
  const [mappingToDelete, setMappingToDelete] = useState(null);

  const shippingMappingOptions = (shipmentMapping) => {
    return [
      { 
        value: 'Edit Mapping', 
        label: 'Edit Mapping', 
        onClick: () => setEditingMapping(shipmentMapping)
      },
      { 
        value: 'Delete', 
        label: 'Delete', 
        destructive: true,
        onClick: () => {
          setMappingToDelete(shipmentMapping);
          showDeleteMappingModal(true);
        }
      },
    ];
  };

  const handleDeleteMapping = () => {
    const updatedShippingMappings = facilityDetails.shippingMappings.filter(
      mapping => mapping !== mappingToDelete
    );

    const updatedFacilityDetails = {
      ...facilityDetails,
      shippingMappings: updatedShippingMappings,
    };

    dispatch(updateFacility({ vendorId, facilityId, data: updatedFacilityDetails }));
    showDeleteMappingModal(false);
    setMappingToDelete(null);
  };

  if (editingMapping) {
    return (
      <AddEditShippingMapping 
        vendorId={vendorId}
        facilityId={facilityId}
        shippingMapping={editingMapping} 
        facilityDetails={facilityDetails} 
        backToShippingMapping={() => setEditingMapping(null)}
      />
    );
  }

  return (
    <div className="shipping-mapping-view">
      {(facilityDetails || facilityDetailsErrorsFound) && (
        <Card className="shipping-mapping-card">
          <Card.Header hideDivider>
            Shipping Mapping
            {facilityDetails && !facilityDetailsErrorsFound && (
              <div className="shipping-mapping-options">
                <Button
                  variant="primary"
                  size="small"
                  label="Add Mapping"
                  onClick={() => setEditingMapping({})}
                />
              </div>
            )}
          </Card.Header>
          <Card.Body>
            <Table size="medium">
              <TableHeader
                options={[
                  { id: "vendorSku", label: "Shipping Method" },
                  { id: "variantId", label: "Carrier Name" },
                  { id: "orderMeshSku", label: "Services Method" },
                  { id: "prodTime", label: "Carrier Code" },
                  { id: "cost", label: "Service Code" },
                  { id: "status", label: "Country Codes to Apply" },
                  { id: "actions", label: "Actions", align: 'center' },
                ]}
              />
              <tbody className="table-body">
                {facilityDetails?.shippingMappings?.map((shippingMapping, index) => (
                  shippingMapping.shippingMethods.map((method, methodIndex) => (
                    method.carrierShippingServiceMethods.map((serviceMethod, serviceIndex) => (
                      <tr className="shipping-mapping-row" key={`${index}-${methodIndex}-${serviceIndex}`}>
                        <td>{method.shipmentMethod}</td>
                        <td>{shippingMapping.carrierName}</td>
                        <td>{serviceMethod.serviceMethodsAllowed.join(', ')}</td>
                        <td>{serviceMethod.vendorMapping.carrierCode}</td>
                        <td>{serviceMethod.vendorMapping.serviceCode}</td>
                        <td>{serviceMethod.countryCodesToApply.join(', ')}</td>
                        <td className="action-icons">
                          <ButtonIcon 
                            icon={<ThreeDotsVertical />} 
                            options={shippingMappingOptions(shippingMapping)} 
                          />
                        </td>
                      </tr>
                    ))
                  ))
                ))}
                {facilityDetails?.shippingMappings?.length === 0 && (
                  <tr>
                    <td colSpan="7" className="no-data">
                      No shipping mappings found.
                    </td>
                  </tr>
                )}
                {facilityDetailsErrorsFound && (
                  <tr>
                    <td colSpan="7" className="no-data"><ExclamationCircle />Error fetching vendor details</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Card.Body>
          {deleteMappingModal && (
            <Modal
              secondaryButtonLabel="No, Keep It"
              secondaryButtonOnClick={() => showDeleteMappingModal(false)}
              primaryButtonLabel="Yes, Delete It"
              primaryButtonVariant="primary"
              primaryButtonDestructive
              primaryButtonOnClick={handleDeleteMapping}
              onClose={() => showDeleteMappingModal(false)}
            >
              <div>Are you sure you want to delete this shipping mapping?</div>
              <div>This action is not reversible.</div>
            </Modal>
          )}
        </Card>
      )}
    </div>
  );
};

export default ShippingMappingView;
