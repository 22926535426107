import React, { useEffect, useMemo, useRef } from 'react';
import { sidebarSelector } from 'app/store/selectors/config';
import { logoutUser } from 'app/store/actions/user';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getOrders } from 'app/store/actions/order';
import { currentUserSelector } from 'app/store/selectors/user';
import ViewAsMenu from './ViewAsMenu';
import {
  House,
  HouseFill,
  Handbag,
  HandbagFill,
  People,
  PeopleFill,
  Shop,
  Truck,
  SignTurnSlightRight,
  SignTurnSlightRightFill,
  ArrowLeftSquare,
  Bell,
  BellFill,
  Plug,
  PlugFill,
} from 'react-bootstrap-icons';
import { ReactComponent as ShopFilledIcon } from 'app/assets/images/icons/shop-filled.svg';
import { ReactComponent as TruckFilledIcon } from 'app/assets/images/icons/truck-filled.svg';
import { ReactComponent as Inventory } from 'app/assets/images/icons/inventory.svg';
import { ReactComponent as InventoryFilled } from 'app/assets/images/icons/inventory-filled.svg'
import { usePermission } from 'app/permissions';
import './index.scss';

const Sidebar = () => {
  const { t } = useTranslation();
  const sidebar = useSelector(sidebarSelector);
  const location = useLocation();
  const dispatch = useDispatch();
  const previousPath = useRef(location.pathname);

  const canGetOrders = usePermission('order', 'search');
  const canReadInsights = usePermission('insight', 'get');
  const canGetIntegrations = usePermission('transformation', 'get');
  const canGetStrategies = usePermission('routing', 'strategies_get');
  const canGetNotifications = usePermission('notification', 'get');
  const canSearchUsers = usePermission('users', 'search');
  const canCreateUsers = usePermission('users', 'users_create');
  const canSearchVendors = usePermission('vendor', 'search');
  const canSearchMerchants = usePermission('merchant', 'get');

  const currentUser = useSelector(currentUserSelector);
  const { merchantIds, vendorIds } = currentUser.attributes || {};

  useEffect(() => {
    // this useEffect is used to refresh the data on a specific page when the user clicks on the same menu item as they are already viewing
    if (previousPath?.current === '/orders') {
      dispatch(getOrders({}));
    }
    previousPath.current = location.pathname;
  }, [location]);

  const shouldShowViewAsMenu = useMemo(() => {
    return !(
      (merchantIds && merchantIds.length === 1) ||
      (vendorIds && vendorIds.length === 1)
    );
  }, [merchantIds, vendorIds]);

  const routes = useMemo(() => [
    { path: '/', name: t('general.home'), icon: House, iconSelected: HouseFill, permission: canReadInsights },
    { path: '/orders', name: t('general.orders'), icon: Handbag, iconSelected: HandbagFill, permission: canGetOrders },
  ], [t, canReadInsights, canGetOrders]);

  const adminRoutes = useMemo(() => [
    { path: '/admin/users', name: 'Users & Roles', icon: People, iconSelected: PeopleFill, permission: canSearchUsers || canCreateUsers },
    { path: '/admin/merchants', name: 'Merchants', icon: Shop, iconSelected: ShopFilledIcon, permission: canSearchMerchants },
    { path: '/admin/vendors', name: 'Vendors', icon: Truck, iconSelected: TruckFilledIcon, permission: canSearchVendors },
    { path: '/admin/routing', name: 'Routing', icon: SignTurnSlightRight, iconSelected: SignTurnSlightRightFill, permission: canGetStrategies },
    { path: '/admin/webhooks', name: 'Webhooks', icon: Bell, iconSelected: BellFill, permission: canGetNotifications },
    { path: '/admin/integrations', name: 'Integrations', icon: Plug, iconSelected: PlugFill, permission: canGetIntegrations },
    { path: '/admin/inventory', name: 'Inventory', icon: Inventory, iconSelected: InventoryFilled },
  ], [canSearchUsers, canSearchMerchants, canSearchVendors, canGetStrategies, canGetNotifications, canGetIntegrations]);

  const renderedRoutes = useMemo(() => routes.map((route, index) => {
    if (route.permission === false) {
      return null;
    }

    const basePath = location.pathname.split('/')[1];
    const isActive = `/${basePath}` === route.path;

    return (
      <React.Fragment key={route.path}>
        <Link
          key={index}
          to={route.path}
          className={`sidebar-link ${isActive ? 'active' : ''}`}
        >
          {isActive ? <route.iconSelected /> : <route.icon />}
          {route.name}
        </Link>
      </React.Fragment>
    );
  }), [routes, location.pathname]);

  const renderedAdminRoutes = useMemo(() => adminRoutes.map((route, index) => {
    if (route.permission === false) {
      return null;
    }

    const basePath = location.pathname.split('/')[2];
    const isActive = `/admin/${basePath}` === route.path;

    return (
      <Link
        key={index}
        to={route.path}
        className={`sidebar-link ${isActive ? 'active' : ''}`}
      >
        {isActive ? <route.iconSelected /> : <route.icon />}
        {route.name}
      </Link>
    );
  }), [adminRoutes, location.pathname]);

  return (
    <div className={`sidebar ${sidebar.open ? 'show' : ''}`}>
      {shouldShowViewAsMenu && <ViewAsMenu />}
      <div className={`sidebar-container  ${shouldShowViewAsMenu ? 'view-as-shown' : ''}`}>
        {renderedRoutes}
        {adminRoutes.length > 0 && (
          <>
            <div className="admin-header">ADMIN SETTINGS</div>
            {renderedAdminRoutes}
          </>
        )}
        <div className="logout-button">
          <Link
            onClick={() => {
              dispatch(logoutUser());
            }}
            className="sidebar-link"
          >
            <ArrowLeftSquare />
            Logout
          </Link>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Sidebar);
