import React, { useState } from 'react';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, ButtonIcon, Dropdown, MessageBar } from 'app/components';
import { updateIntegration } from 'app/store/actions/integration';
import { ChevronDown, ChevronLeft } from 'react-bootstrap-icons';
import { object, string, mixed } from 'yup';
import Editor from '@monaco-editor/react';
import './index.scss';

const ActionsAndTrigger = (props) => {
  const { details, eventTypes, integrationDetails, pluginId, pluginVersionId, currentVersion, editMode, setEditModeId, editModeId, setNewActionsAndTriggers } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(true);

  function handleEditorBeforeMount(monaco) {
    // ensure that the 'vs-dark' theme is applied before the editor mounts (dark mode is buggy sometimes so we manually set it here)
    setTimeout(() => {
      monaco.editor.setTheme('vs-dark');
    }, 1); 
  }

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="actions-and-trigger">
      <Formik
        enableReinitialize
        initialValues={{
          selectedEventObject: eventTypes.eventsCatalog.find(event => event.id === details.eventId) || null,
          codeSnippet: details?.transformationData?.snippets?.[details?.transformationData?.snippets.length - 1]?.code || '',
        }}
        validationSchema={() =>
          object({
            selectedEventObject: mixed()
              .required('Event is required')
              .test('is-valid-object', 'A valid event must be selected', value => value && value.id),
            codeSnippet: string().required("Code snippet is required"),
          })
        }
        onSubmit={async (values) => {
          // make a copy of the events object
          const newEvents = integrationDetails.details && integrationDetails.details.events ? [...integrationDetails.details.events] : [];

          // find the index of the event we are editing
          let eventIndex = newEvents.findIndex(event => event.eventId === details.eventId);

          // check to see if the code snippets have changed
          const transformationChanged = details?.codeSnippet?.snippets?.[0]?.code !== values.codeSnippet;

          // update or add the event object with the new values
          const updatedEvent = {
            eventId: values.selectedEventObject.id,
            transformationId: details?.transformationId,
          };

          if (eventIndex !== -1) {
            newEvents[eventIndex] = {
              ...newEvents[eventIndex],
              ...updatedEvent
            };
          } else {
            newEvents.push(updatedEvent);
            eventIndex = newEvents.length - 1;
          }

          const data = {
            events: newEvents
          };

          dispatch(updateIntegration({
            transformationChanged,
            transformationId: details.transformationId,
            snippetId: details.snippetId,
            pluginId,
            pluginVersionId: pluginVersionId && pluginVersionId !== '0' ? pluginVersionId : null,
            currentVersion,
            data,
            codeSnippet: values.codeSnippet,
            integratorId: integrationDetails.basicInfo.integratorId,
            creatorType: integrationDetails.basicInfo.type,
            topic: values.selectedEventObject.name, 
            eventIndex,
            cb: (updatedPluginVersionId) => {
              navigate(`/admin/integrations/${pluginId}/${updatedPluginVersionId}`);
            }
          }));
        }}

      >
        {({
          values,
          errors,
          handleSubmit,
          setFieldValue,
          submitCount,
        }) => (
          <form onSubmit={handleSubmit}>
            {details?.eventId && (
              <div className={`actions-and-trigger-header ${isMenuOpen ? 'menu-open' : ''}`}>
                <div className="action-name">{details.eventId === -1 ? 'New Action' : eventTypes.eventsCatalog?.find(event => event.id === details.eventId)?.name}</div>
                <div className="action-buttons">
                  {!editModeId && editMode && (
                    <Button
                      variant="secondary"
                      size="small"
                      label="Edit Action"
                      onClick={() => {
                        if (!isMenuOpen) setIsMenuOpen(true);
                        setEditModeId(details.eventId);
                      }}
                    />
                  )}
                  <ButtonIcon
                    icon={isMenuOpen ? <ChevronDown /> : <ChevronLeft />}
                    onClick={toggleMenu}
                  />
                  {editModeId === details.eventId && (
                    <>
                      <Button
                        variant="secondary"
                        size="small"
                        label="Cancel"
                        onClick={() => {
                          if (editModeId === -1) {
                            // Remove the new action
                            setNewActionsAndTriggers([]);
                          }
                          setEditModeId(null);
                        }}
                      />
                      <Button
                        variant="primary"
                        size="small"
                        label="Save Changes"
                        onClick={handleSubmit}
                      />
                    </>
                  )}
                </div>
              </div>
            )}
            {submitCount > 0 && errors.codeSnippet && (
              <MessageBar color="yellow">
                {`${errors.requestCodeSnippet && errors.responseCodeSnippet && 'The Code Snippet is required.'}`}
              </MessageBar>
            )}
            {isMenuOpen && (
              <>
                <div className="actions-and-trigger-body">
                  {editMode && editModeId === details.eventId ? (
                    <>
                      <div className="edit-mode-header">
                        <Dropdown
                          label="Event"
                          name="selectedEventObject"
                          className="selected-event"
                          placeholder="Select an Event"
                          value={values.selectedEventObject?.name || ''}
                          onChange={(e) => {
                            const selectedEvent = eventTypes.eventsCatalog.find(event => event.name === e.target.value);
                            setFieldValue('selectedEventObject', selectedEvent);
                          }}
                          options={eventTypes?.eventsCatalog.map((event) => ({ value: event.name, label: event.name }))}
                          errorMessage={submitCount > 0 && errors.selectedEventObject}
                        />
                      </div>
                      <div className="api-config-header">Customization</div>
                      <div className="code-snippet-header">Code Snippet</div>
                      <div>
                        <Editor
                          className="code-snippet"
                          options={{
                            overviewRulerLanes: 0,
                            minimap: { enabled: false },
                            wordWrap: 'on',
                            theme: 'vs-dark',
                          }}
                          height="500px"
                          defaultLanguage="javascript"
                          defaultValue="// add your code here"
                          value={values.codeSnippet}
                          onChange={(value) => {
                            setFieldValue('codeSnippet', value);
                          }}
                          beforeMount={handleEditorBeforeMount}
                        />
                      </div>
                    </>
                  ) : (
                    <div className="view-mode-container">
                      <div className="code-snippet-header">Code Snippet</div>
                      <div>
                        <Editor
                          className="code-snippet"
                          options={{
                            readOnly: true,
                            overviewRulerLanes: 0,
                            minimap: { enabled: false },
                            wordWrap: 'on',
                            theme: 'vs-dark',
                          }}
                          height="500px"
                          defaultLanguage="javascript"
                          defaultValue="// add your code here"
                          value={values.codeSnippet}
                          beforeMount={handleEditorBeforeMount}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
          </form>
        )}
      </Formik>
    </div>
  );
};

export default ActionsAndTrigger;
