import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ButtonIcon, Card, TabbedMenu, LoadingAnimation, MessageBar, IconData, Button } from 'app/components';
import { integrationDetailsSelector, integrationDetailsLoadingSelector, integrationDetailsErrorsSelector } from 'app/store/selectors/integration';
import { getIntegrationVersions, publishIntegrationVersion, getIntegrationDetails } from 'app/store/actions/integration';
import VariablesView from './VariablesView';
import ActionsAndTriggers from './ActionsAndTriggers';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft, PlugFill, Person } from 'react-bootstrap-icons';
import { ReactComponent as IndustryWindows } from 'app/assets/images/icons/industry-windows.svg';
import { ReactComponent as CodeBranch } from 'app/assets/images/icons/code-branch.svg';
import { usePermission } from 'app/permissions';
import './index.scss';

const tabs = [
  {
    path: 'variables',
    label: 'Variables',
    content: VariablesView,
  },
  {
    path: 'actions-and-triggers',
    label: 'Actions and Triggers',
    content: ActionsAndTriggers,
  },
];

const IntegrationDetails = () => {
  const { pluginId, pluginVersionId } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [editMode, setEditMode] = useState((pluginVersionId && pluginVersionId !== '0') ? false : true);

  const integrationDetails = useSelector(integrationDetailsSelector);
  const integrationDetailsLoading = useSelector(integrationDetailsLoadingSelector);
  const integrationDetailsErrors = useSelector(integrationDetailsErrorsSelector);

  // get user permissions
  const canUpdateIntegrations = usePermission('notification', 'create');

  useEffect(() => {
    dispatch(getIntegrationVersions({ pluginId, cb: onVersionsLoaded }));
  }, []);

  const onVersionsLoaded = (currentPluginVersion) => {
    if (currentPluginVersion) {
      setEditMode(false);
      navigate(`/admin/integrations/${pluginId}/${currentPluginVersion}`);
    }
  }

  const currentVersion = integrationDetails?.details;

  const getDisplayVersion = () => {
    if (currentVersion && Object.keys(currentVersion).length > 0) {
      if (currentVersion.isDraft) {
        return `Draft Version ${currentVersion.name}`;
      }
      if (editMode) {
        // string replace the last letter with a 1
        const versionNumber = currentVersion.name.replace(/.$/, '1');
        return `Draft Version ${versionNumber}`;
      }
      return `Published Version ${currentVersion.name}`;
    }
    return 'Draft Version 0.1';
  }

  const navigateToVersion = (version) => {
    if (window.location.pathname.includes('actions-and-triggers')) {
      navigate(`/admin/integrations/${pluginId}/${version}/actions-and-triggers`);
    } else {
      navigate(`/admin/integrations/${pluginId}/${version}`);
    }
  }

  return (
    <div className="integration-details">
      {integrationDetailsLoading && <LoadingAnimation />}
      {(integrationDetails?.details || integrationDetailsErrors) && (
        <>
          <Card className="header-card">
            <div className="header-container">
              <div className="merchant-name">
                <ButtonIcon icon={<ArrowLeft />} onClick={() => navigate('/admin/integrations/manage-integrations')} />
                {integrationDetails?.basicInfo?.name || 'Integration Details'}
              </div>
              {canUpdateIntegrations && (
                <div className="action-buttons">
                  {pluginVersionId === '0' && (
                    <Button
                      variant="secondary"
                      size="medium"
                      label="Publish Later"
                      onClick={() => {
                        navigate('/admin/integrations');
                      }}
                    />
                  )}
                  {editMode && integrationDetails?.versions?.find(version => version.name.endsWith('.0')) && (
                    <Button
                      variant="secondary"
                      size="medium"
                      label="View Published Version"
                      onClick={() => {
                        const publishedVersion = integrationDetails?.versions.filter(version => version.name.endsWith('.0')).slice().sort((a, b) => parseFloat(b.name) - parseFloat(a.name))[0];
                        dispatch(getIntegrationDetails({ pluginId, pluginVersionId: publishedVersion.id, cb: () => { setEditMode(false) } }));
                        navigateToVersion(publishedVersion.id);
                      }}
                    />
                  )}
                  {!editMode && (
                    <Button
                      variant="primary"
                      size="medium"
                      label="Edit Integration"
                      // disabled={loading}
                      onClick={() => {
                        // in the integrationDetails object, find the absolute latest version
                        const latestVersion = integrationDetails?.versions?.slice().sort((a, b) => parseFloat(b.name) - parseFloat(a.name))[0];
                        dispatch(getIntegrationDetails({ pluginId, pluginVersionId: latestVersion.id, cb: () => { setEditMode(true) } }));
                        navigateToVersion(latestVersion.id);
                      }}
                    />
                  )}
                  {(currentVersion?.isDraft || pluginVersionId === '0') && (
                    <Button
                      variant="primary"
                      size="medium"
                      label="Publish Integration"
                      disabled={pluginVersionId === '0' || integrationDetails?.details?.events.length === 0}
                      onClick={() => {
                        dispatch(publishIntegrationVersion({ pluginId, pluginVersionId, cb: (highestVersionId) => {
                          navigateToVersion(highestVersionId);
                          setEditMode(false);
                        }}));
                      }}
                    />
                  )}
                </div>
              )}
            </div>
            <div className="integration-data-points">
              {integrationDetails?.basicInfo?.type && <IconData label={integrationDetails.basicInfo.type} icon={<PlugFill />} />}
              {integrationDetails?.basicInfo?.integratorId && <IconData label={integrationDetails.basicInfo.integratorId} icon={<IndustryWindows />} />}
              {integrationDetails?.basicInfo?.isPrivate !== undefined && integrationDetails.basicInfo.isPrivate !== null && <IconData label={integrationDetails.basicInfo.isPrivate ? 'Private' : 'Public'} icon={<Person />} />}
              <IconData label={getDisplayVersion()} icon={<CodeBranch />} />
            </div>
          </Card>
          {!integrationDetailsErrors && <TabbedMenu tabs={tabs} showTabs={Boolean(pluginId)} pluginId={pluginId} pluginVersionId={pluginVersionId} currentVersion={currentVersion} editMode={editMode} />}
          {integrationDetailsErrors && <MessageBar color="yellow">An error occurred while fetching data</MessageBar>}
        </>
      )}
    </div>
  )
}

export default IntegrationDetails;