import React from 'react';
import { useNavigate } from "react-router-dom";
import { Card, ButtonIcon, StatusChip, IconData, Button, MessageBar } from 'app/components';
import { ThreeDotsVertical, ChevronDown, ChevronLeft, Truck, Radar, XCircleFill } from 'react-bootstrap-icons';
import { usePermission } from 'app/permissions';
import PackageCard from './PackageCard';
import './index.scss';

const ShipmentCard = props => {
  const {
    orderId,
    shipment,
    menuState,
    toggleMenu,
    showEditTrackingModal,
    showCancelShipmentModal,
    showCancelOrderItemModal,
    showCarrierLabelModal,
    showChangeVendorModal,
    showRejectShipmentModal,
    showUnassignItemsModal,
    showVendorInfoModal,
  } = props;

  const navigate = useNavigate();

  // get user permissions
  const canUpdateShipments = usePermission('order', 'update');

  const shipmentOptions = () => [
    { value: 'Update Status', label: 'Update Status', onClick: () => { console.log("Not implemented yet") } },
    { value: 'Edit Tracking', label: 'Edit Tracking', onClick: () => { showEditTrackingModal({ shipment }) } },
    { value: 'Reroute All Items', label: 'Reroute All Items', onClick: () => showChangeVendorModal({ shipmentId: shipment.id, shipmentShortId: shipment.shortId }) },
    { value: 'Unassign All Items', label: 'Unassign All Items', onClick: () => showUnassignItemsModal({ shipmentId: shipment.id, itemIds: shipment.orderItemIDs, isAllItems: true }) },
    { value: 'Vendor Information', label: 'Vendor Information', onClick: () => { showVendorInfoModal({ vendorId: shipment.vendorId }) } },
    { value: 'Reject Shipment', label: 'Reject Shipment', destructive: true, onClick: () => { showRejectShipmentModal(shipment) } },
    ...(shipment.shipmentStatus !== 'Canceled' ? [{ value: 'Cancel Shipment', label: 'Cancel Shipment', destructive: true, onClick: () => showCancelShipmentModal(shipment) }] : []),
  ];

  const numItems = shipment.packages.reduce((total, pkg) => total + pkg.orderItemIDs.length, 0);

  return (
    <Card className="shipment-card">
      <Card.Header>
        <div className={`shipment-header ${menuState[shipment.shortId] && 'collapsed'}`}>
          <div className="shipment-header-body">
            <div className="id-status">
              <div>{`Shipment ${shipment.shortId}`}</div>
              <StatusChip type="shipment" size="slim" status={shipment.shipmentStatus} darkMode={true} />
            </div>
            <div className="shipment-data-and-options">
              {shipment.vendorName && (
                <IconData
                  label={shipment.vendorName}
                  icon={<Truck />}
                  onClick={shipment.vendorId ? () => navigate("/admin/vendors/" + shipment.vendorId) : undefined}
                  darkMode={true}
                />
              )}
              {shipment.packages.length === 1 && shipment.packages[0].carrierName && (
                <IconData
                  label={shipment.packages[0].carrierName}
                  icon={<Radar />}
                  onClick={shipment.packages[0].carrierLink ? () => window.open(shipment.packages[0].carrierLink, '_blank') : undefined}
                  darkMode={true}
                />
              )}
              <div className="shipment-options">
                {canUpdateShipments && (
                  <ButtonIcon
                    icon={<ThreeDotsVertical />}
                    options={shipmentOptions()}
                    darkMode={true}
                  />
                )}
                {numItems > 0 && (
                  <ButtonIcon
                    className="collapse-menu"
                    icon={menuState[shipment.shortId] ? <ChevronLeft /> : <ChevronDown />}
                    onClick={() => toggleMenu(shipment.shortId)}
                    darkMode={true}
                  />
                )}
              </div>
            </div>
          </div>
          {shipment.vendorName && (
            <div className="additional-shipment-info">
              <div className="info-field">
                <div>Facility Name</div>
                <div>{shipment.vendorName}</div>
              </div>
            </div>
          )}
          {shipment.shipmentStatus === 'FulfillmentError' && (                
            <MessageBar color="red" darkMode icon={<XCircleFill />}>
              Shipment failed submission to the vendor
              <Button
                label="Resubmit to Vendor"
                destructive
                size="small"
                onClick={() => console.log("Not implemented yet")}
              />
            </MessageBar>
          )}
        </div>
      </Card.Header>
      {!menuState[shipment.shortId] && (
        <Card.Body>
          {shipment.packages.map((orderPackage) => (
            <PackageCard
              key={orderPackage.id}
              orderId={orderId}
              shipment={shipment}
              orderPackage={orderPackage}
              numPackages={shipment.packages.length}
              showCancelOrderItemModal={showCancelOrderItemModal}
              showCarrierLabelModal={showCarrierLabelModal}
              showChangeVendorModal={showChangeVendorModal}
              showUnassignItemsModal={showUnassignItemsModal}
            />
          ))}
          {shipment.packages.length === 0 && (
            <div>No packages found in shipment</div>
          )}
        </Card.Body>
      )}
    </Card>
  );
}

export default ShipmentCard;