import React from 'react';
import { useDispatch } from 'react-redux';
import { Modal, Button } from 'app/components';
import { unassignItems } from 'app/store/actions/shipment';
import './index.scss';

const UnassignItemsModal = (props) => {
  const { orderId, shipmentId, itemIds, singleItemShortId, isAllItems, showUnassignItemsModal } = props;
  const dispatch = useDispatch();

  return (
    <Modal
      className="unassign-items-modal"
      title={isAllItems ? `Unassign all in Shipment` : `Unassign Item ${singleItemShortId}`}
      hideButtons={true}
      onClose={() => { showUnassignItemsModal(null); }}
    >
      <div className="body-message">
        {isAllItems ? `Are you sure you want to unassign all items in this shipment?` : `Are you sure you want to unassign item ${singleItemShortId}?`}
      </div>
      <div className="action-buttons">
        <Button
          variant="secondary"
          size="small"
          label="Cancel"
          onClick={() => { showUnassignItemsModal(null); }}
        />
        <Button
          variant="primary"
          size="small"
          label={isAllItems ? `Unassign Items` : `Unassign Item`}
          onClick={() => {
            dispatch(unassignItems({ orderId, shipmentId, itemIds }));
            showUnassignItemsModal(null);
          }}
        />
      </div>
    </Modal>
  );
};

export default UnassignItemsModal;
