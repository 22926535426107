import React from 'react';
import { Card } from 'app/components';
import './index.scss';

const PaymentInformation = () => {
  return (
    <Card className="payment-information">
      <Card.Header>
        Payment Information
      </Card.Header>
      <Card.Body>
        <div className="data-value">Jack Fazo</div>
        <div className="data-value">221B Baker Street New York, NY 10012, US</div>
        <div className="data-value">+12345467890</div>
        <div className="data-value">********2324</div>
      </Card.Body>
    </Card>
  )
}

export default PaymentInformation;