import React, { useState, useMemo } from 'react';
import { ChevronDown, ChevronLeft, ThreeDotsVertical } from 'react-bootstrap-icons';
import { Card, ButtonIcon, Pagination } from 'app/components';
import { usePermission } from 'app/permissions';
import OrderItem from '../OrderItem';
import './index.scss';

const OrderItemsCard = (props) => {
  const {
    orderId,
    orderItems, 
    menuState, 
    toggleMenu,
    showCancelOrderItemModal
  } = props;

  // get user permissions
  const canUpdateShipments = usePermission('order', 'update');

  const itemOptions = () => [
    { value: 'Create Shipment', label: 'Create Shipment', onClick: () => { console.log("Not implemented yet") }},
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);

  // calculate the displayed items for the current page (based on pagination)
  const displayedItems = useMemo(() => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return orderItems.slice(startIndex, endIndex);
  }, [orderItems, currentPage, pageSize]);

  const totalItems = useMemo(() => orderItems.length, [orderItems]);

  return (
    <Card className="unassigned-items-card">
      <Card.Header>
        <div className={`unassigned-items-header ${menuState['unassignedItems'] && 'collapsed'}`}>
          <div className="header-title">
            No Shipment Assigned
          </div>
          <div className="item-options">
            {canUpdateShipments && (
              <ButtonIcon
                icon={<ThreeDotsVertical />}
                options={itemOptions()}
                darkMode={true}
              />
            )}
            <ButtonIcon
              icon={menuState['unassignedItems'] ? <ChevronLeft /> : <ChevronDown />}
              onClick={() => toggleMenu('unassignedItems')}
              darkMode={true}
            />
          </div>
        </div>
      </Card.Header>
      {!menuState['unassignedItems'] && (
        <Card.Body>
          {displayedItems.map((orderItem) => (
            <OrderItem
              key={orderItem.id}
              orderId={orderId}
              itemInfo={orderItem}
              showCancelOrderItemModal={showCancelOrderItemModal}
            />
          ))}
          
          {totalItems > 5 && (
            <div className="pagination-container">
              <Pagination
                totalItems={totalItems}
                pageSizes={[5, 10, 20, 50]}
                hideBelow={5}
                currentPage={currentPage}
                pageSize={pageSize}
                onChange={(page, newPageSize) => {
                  setCurrentPage(page);
                  setPageSize(newPageSize);
                }}
              />
            </div>
          )}
        </Card.Body>
      )}
    </Card>
  );
};

export default OrderItemsCard;