import React, { useState } from "react";
import { useSelector } from "react-redux";
import { notesDataSelector, notesErrorsSelector } from "app/store/selectors/note";
import { Card, ButtonIcon } from "app/components";
import { ChevronDown, ChevronLeft, Lock, FileText, PlusCircle } from "react-bootstrap-icons";
import moment from "moment";
import "./index.scss";
import { useNavigate } from "react-router-dom";

const OrderNotes = () => {
  const notesData = useSelector(notesDataSelector);
  const notesDataError = useSelector(notesErrorsSelector);
  const [isMenuOpen, setIsMenuOpen] = useState(true);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <form>
      <Card className="notes-card">
        <Card.Header className={!isMenuOpen ? 'collapsed' : ''}>
          <div className="note-header">
            Notes
            <div className="note-count">({notesData?.length || 0})</div>
          </div>
          <div>
            <ButtonIcon
              icon={<PlusCircle />}
              onClick={() => {
                navigate("history");
              }}
            />
            <ButtonIcon icon={isMenuOpen ? <ChevronDown /> : <ChevronLeft />} onClick={() => toggleMenu()} />
          </div>
        </Card.Header>
        <Card.Body>
          {notesData && isMenuOpen && (
            <div className="notes-card-data">
              {notesData.map((note) => (
                <div className="note-row" key={note.id}>
                  <div className="note-row-left">
                    <div className="note-icon">{note.isPrivate ? <Lock /> : <FileText />}</div>
                    <div className="vertical-line" />
                  </div>
                  <div className="note-row-right">
                    <div className="note-row-date">{moment(note.createdAt).local().format("MMM DD, YYYY h:mm a")}</div>
                    <div className="note-row-author">{note.createdBy?.userName}</div>
                    <div className="note-row-description" dangerouslySetInnerHTML={{ __html: note.content }} />
                  </div>
                </div>
              ))}
            </div>
          )}
          {notesData && notesData.length === 0 && <div>No notes found.</div>}
          {notesDataError && <div>{notesDataError}</div>}
        </Card.Body>
      </Card>
    </form>
  );
};

export default OrderNotes;
