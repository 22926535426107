import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { SearchBar, MessageBar, Dropdown } from 'app/components';
import { orderDetailsSelector, orderDetailsErrorsSelector } from 'app/store/selectors/order';
import { shipmentsSelector } from 'app/store/selectors/shipment';
import UnassignedItemsCard from './Cards/UnassignedItemsCard';
import DetailsCard from './Cards/DetailsCard';
import ShipmentCard from './Cards/ShipmentCard';
import EditTrackingModal from './Modals/EditTrackingModal';
import CancelShipmentModal from './Modals/CancelShipmentModal';
import CancelOrderItemModal from './Modals/CancelOrderItemModal';
import RerouteOrderItemModal from './Modals/RerouteOrderItemModal';
import RejectShipmentModal from './Modals/RejectShipmentModal';
import UnassignItemsModal from './Modals/UnassignItemsModal';
import VendorInfoModal from './Modals/VendorInfoModal';
import FullImagePreview from './Modals/FullImagePreview';
import defaultCarrierLabel from 'app/assets/images/defaultCarrierLabel.png';
import './index.scss';

const SummaryView = () => {
  const orderDetails = useSelector(orderDetailsSelector);
  const orderDetailsErrors = useSelector(orderDetailsErrorsSelector);
  const shipments = useSelector(shipmentsSelector);

  const [menuState, setMenuState] = useState({});
  const [searchQuery, setSearchQuery] = useState(null);
  const [statusFilter, setStatusFilter] = useState(null);
  const [editTrackingModal, showEditTrackingModal] = useState(null);
  const [changeVendorModal, showChangeVendorModal] = useState(null);
  const [cancelShipmentModal, showCancelShipmentModal] = useState(null);
  const [cancelOrderItemModal, showCancelOrderItemModal] = useState(null);
  const [carrierLabelModal, showCarrierLabelModal] = useState(null);
  const [rejectShipmentModal, showRejectShipmentModal] = useState(null);
  const [unassignItemsModal, showUnassignItemsModal] = useState(null);
  const [vendorInfoModal, showVendorInfoModal] = useState(null);

  useEffect(() => {
    // initialize menu state for shipments with multiple items (if more than 5 items, default to collapsed)
    if (shipments) {
      const updatedMenuState = { ...menuState };
      
      shipments.forEach((shipment) => {
        const itemsCount = shipment.packages.reduce((total, pkg) => total + pkg.orderItemIDs.length, 0);
        if (itemsCount > 5) {
          updatedMenuState[shipment.shortId] = true;
        }
      });
      setMenuState(updatedMenuState);
    }
  }, [shipments]);

  const toggleMenu = (menuId) => {
    setMenuState((prevState) => ({
      ...prevState,
      [menuId]: !prevState[menuId]
    }));
  };

  const { parsedShipments, unmatchedOrderItems } = useMemo(() => {
    if (!orderDetails || !shipments) {
      return { parsedShipments: [], unmatchedOrderItems: [] };
    }
  
    const parsedShipments = [];
    // Create a copy of the order items.  We will look through this array to determine which items are not assigned to a shipment
    const unmatchedOrderItems = orderDetails.items.map(item => ({ ...item }));
  
    shipments
      // filter out archived shipments
      .filter(shipment => shipment.shipmentStatus !== 'Archived')
      // then for each shipment
      .forEach((shipment) => {
        // create a copy of the shipment and remove the orderItems
        const shipmentWithItems = { ...shipment, orderItems: [] };
  
        // then for each shipment, loop over every orderItemID within it
        shipment.orderItems.forEach((shipmentItem) => {
          // find the orderItem in the orderDetails
          const orderItemIndex = unmatchedOrderItems.findIndex(orderItem => orderItem.id === shipmentItem.id);
          
          if (orderItemIndex !== -1) {
            const orderItem = unmatchedOrderItems[orderItemIndex];
            const quantityToMove = shipmentItem.quantity; 
  
            // reduce the quantity from unmatchedOrderItems
            orderItem.quantity -= quantityToMove;

            // remove the item if quantity is 0
            if (orderItem.quantity === 0) {
              unmatchedOrderItems.splice(orderItemIndex, 1);
            } else if (orderItem.quantity < 0) {
              console.error(`Order item ${orderItem.id} has negative quantity`);
            }

            // finally, add the orderItem to the shipment
            shipmentWithItems.orderItems.push({
              ...orderItem,
              quantity: quantityToMove
            });
          } else {
            console.error(`Order item ${shipmentItem.id} not found in order items`);
          }
        });
  
        parsedShipments.push(shipmentWithItems);
      });
  
    // if unmatchedOrderItems has more than items 5, collapse the card by default
    if (unmatchedOrderItems.length > 5) {
      setMenuState((prevState) => ({
        ...prevState,
        unassignedItems: true
      }));
    }
  
    return { parsedShipments, unmatchedOrderItems };
  }, [shipments, orderDetails]);

  const filteredUnmatchedItems = useMemo(() => {
    return unmatchedOrderItems.filter(item => {
      // check for searchQuery match if searchQuery exists
      const searchQueryMatch = !searchQuery || (
        item.sku?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.shortId?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.id?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.externalId?.toLowerCase().includes(searchQuery.toLowerCase())
      );
  
      // check for statusFilter match if statusFilter exists
      const statusFilterMatch = !statusFilter || item.status === statusFilter;
  
      // return true if both conditions match
      return searchQueryMatch && statusFilterMatch;
    });
  }, [searchQuery, statusFilter, unmatchedOrderItems]);

  const filteredParsedShipments = useMemo(() => {
    return parsedShipments
      .map(shipment => {
        // filter items based on both searchQuery and statusFilter
        const filteredItems = shipment.orderItems.filter(item => {
          const searchQueryMatch = !searchQuery || (
            item.sku?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            item.shortId?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            item.id?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            item.externalId?.toLowerCase().includes(searchQuery.toLowerCase())
          );
  
          const statusFilterMatch = !statusFilter || item.status === statusFilter;
  
          return searchQueryMatch && statusFilterMatch;
        });
  
        // if any items match, update the shipment details
        if (filteredItems.length > 0) {
          const filteredItemIDs = filteredItems.map(item => item.id);
  
          const updatedOrderItemIDs = shipment.orderItemIDs.filter(id => filteredItemIDs.includes(id));
  
          const updatedPackages = shipment.packages.map(pkg => ({
            ...pkg,
            orderItemIDs: pkg.orderItemIDs.filter(id => filteredItemIDs.includes(id))
          }));
  
          return {
            ...shipment,
            orderItems: filteredItems,
            orderItemIDs: updatedOrderItemIDs,
            packages: updatedPackages
          };
        }
  
        return null;
      })
      .filter(shipment => shipment !== null); // filter out shipments with no matching items
  }, [searchQuery, statusFilter, parsedShipments]);
  

  // check if any shipment or unmatched items have more than 5 items
  const showSearchBar = useMemo(() => {
    const hasLargeShipments = parsedShipments.some(shipment => shipment.orderItems.length > 5);
    const hasLargeUnassignedItems = unmatchedOrderItems.length > 5;
    return hasLargeShipments || hasLargeUnassignedItems;
  }, [parsedShipments, unmatchedOrderItems]);
  
  return (
    <div className="summary-view">
      {orderDetails && shipments && (
        <div className="summary-cards">
          <DetailsCard orderDetails={orderDetails} shipments={shipments} />
          {showSearchBar && (
            <div className="search-status-container">
              <SearchBar
                searchPlaceholderText="Search"
                onSearchStringUpdated={(searchString) => setSearchQuery(searchString)}
              />
              <Dropdown
                name="status-filter"
                className="status-filter"
                allowClear={true}
                showErrorMessages={false}
                options={[
                  { value: 'open', label: 'Open' },
                  { value: 'canceled', label: 'Canceled' },
                  { value: 'readyforfulfillment', label: 'ReadyForFulfillment' },
                  { value: 'fulfilled', label: 'Fulfilled' },
                  { value: 'inproduction', label: 'InProduction' },
                  { value: 'imageError', label: 'ImageError' },
                  { value: 'addressissue', label: 'AddressIssue' },
                  { value: 'fulfillmenterror', label: 'FulfillmentError' },
                  { value: 'hold', label: 'Hold' }
                ]}
                placeholder="Filter by Status"
                value={statusFilter}
                onChange={(e) => setStatusFilter(e.target.value)}
              />
            </div>
          )}
          {filteredUnmatchedItems.length > 0 && (
            <UnassignedItemsCard
              orderId={orderDetails.id}
              orderItems={filteredUnmatchedItems} 
              menuState={menuState} 
              toggleMenu={toggleMenu}
              showCancelOrderItemModal={data => showCancelOrderItemModal(data)}
            />
          )}

          {filteredParsedShipments.map((shipment) => (
            <ShipmentCard
              key={shipment.id}
              orderId={orderDetails.id} 
              shipment={shipment}
              menuState={menuState}
              toggleMenu={toggleMenu}
              showEditTrackingModal={data => showEditTrackingModal(data)}
              showCancelShipmentModal={data => showCancelShipmentModal(data)}
              showCancelOrderItemModal={data => showCancelOrderItemModal(data)}
              showCarrierLabelModal={showCarrierLabelModal}
              showChangeVendorModal={data => showChangeVendorModal(data)}
              showRejectShipmentModal={data => showRejectShipmentModal(data)}
              showUnassignItemsModal={data => showUnassignItemsModal(data)}
              showVendorInfoModal={data => showVendorInfoModal(data)}
            />  
          ))}

          {filteredParsedShipments.length === 0 && filteredUnmatchedItems.length === 0 && (
            <div className="no-matching-search-results">
              No items match your search
            </div>
          )}
        </div>
      )}

      {editTrackingModal && (
        <EditTrackingModal
          shipment={editTrackingModal.shipment}
          showEditTrackingModal={showEditTrackingModal}
        />
      )}
      {carrierLabelModal && (
        <FullImagePreview
          imageUrl={defaultCarrierLabel}
          showCarrierLabelModal={showCarrierLabelModal}
        />
      )}

      {changeVendorModal && (
        <RerouteOrderItemModal
          orderId={orderDetails.id}
          shipmentId={changeVendorModal.shipmentId}
          shipmentShortId={changeVendorModal.shipmentShortId}
          itemInfo={changeVendorModal.itemInfo}
          showChangeVendorModal={showChangeVendorModal}
        />
      )}

      {cancelShipmentModal && (
        <CancelShipmentModal
          orderId={orderDetails.id}
          shipment={cancelShipmentModal}
          showCancelShipmentModal={showCancelShipmentModal}
        />
      )}

      {cancelOrderItemModal && (
        <CancelOrderItemModal
          orderId={orderDetails.id}
          itemInfo={cancelOrderItemModal.itemInfo}
          showCancelOrderItemModal={showCancelOrderItemModal}
        />
      )}

      {rejectShipmentModal && (
        <RejectShipmentModal
          shipment={rejectShipmentModal}
          orderId={orderDetails.id}
          showRejectShipmentModal={showRejectShipmentModal}
        />
      )}

      {unassignItemsModal && (
        <UnassignItemsModal
          orderId={orderDetails.id}
          shipmentId={unassignItemsModal.shipmentId}
          itemIds={unassignItemsModal.itemIds}
          singleItemShortId={unassignItemsModal.singleItemShortId}
          isAllItems={unassignItemsModal.isAllItems}
          showUnassignItemsModal={showUnassignItemsModal}
        />
      )}

      {vendorInfoModal && (
        <VendorInfoModal
          vendorId={vendorInfoModal.vendorId}
          showVendorInfoModal={showVendorInfoModal}
        />
      )}

      {orderDetailsErrors && (
        <MessageBar color="yellow">
          An error occurred while fetching order data
        </MessageBar>
      )}
    </div>
  );
};

export default SummaryView;