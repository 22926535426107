import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  createRoutingStrategy,
  updateRoutingStrategy,
  getStrategyInfo,
} from 'app/store/actions/routing';
import {
  createStrategyLoadingSelector,
  updateStrategyLoadingSelector,
  deleteStrategyLoadingSelector,
  strategyInfoSelector,
  strategyInfoLoadingSelector,
  strategyInfoErrorSelector,
} from 'app/store/selectors/routing';
import { categoriesDataSelector, categoriesLoadingSelector, categoriesErrorsSelector } from 'app/store/selectors/catalog';
import { getCategories } from 'app/store/actions/catalog';
import { getVendors } from 'app/store/actions/vendor';
import { getMerchantDetails } from 'app/store/actions/merchant';
import { merchantDetailsSelector, merchantDetailsLoadingSelector, merchantDetailsErrorsSelector } from 'app/store/selectors/merchant';
import { vendorsDataSelector, vendorsLoadingSelector, vendorsErrorsSelector } from 'app/store/selectors/vendor';
import { object, string, array, mixed, ref } from 'yup';
import { Button, Input, LoadingAnimation, Card, ButtonIcon } from 'app/components';
import { ExclamationCircle, ArrowLeft } from 'react-bootstrap-icons';
import { CountryList } from 'app/constants';
import AdditionalStrategyOptions from './AdditionalStrategyOptions';
import Block from './Block';
import './index.scss';

const StrategyEditCreate = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [editMode, setEditMode] = useState(false);

  const vendorsData = useSelector(vendorsDataSelector);
  const vendorsLoading = useSelector(vendorsLoadingSelector);
  const vendorsErrors = useSelector(vendorsErrorsSelector);
  const categories = useSelector(categoriesDataSelector);
  const categoriesLoading = useSelector(categoriesLoadingSelector);
  const categoriesErrors = useSelector(categoriesErrorsSelector);
  const merchantDetailsLoading = useSelector(merchantDetailsLoadingSelector);
  const merchantDetails = useSelector(merchantDetailsSelector);
  const merchantDetailsErrors = useSelector(merchantDetailsErrorsSelector);
  const createStrategyLoading = useSelector(createStrategyLoadingSelector);
  const updateStrategyLoading = useSelector(updateStrategyLoadingSelector);
  const deleteStrategyLoading = useSelector(deleteStrategyLoadingSelector);
  const strategyInfo = useSelector(strategyInfoSelector);
  const strategyInfoLoading = useSelector(strategyInfoLoadingSelector);
  const strategyInfoError = useSelector(strategyInfoErrorSelector);

  const { merchantId, strategyId } = useParams();

  useEffect(() => {
    // we need the list of vendors to render the page so if we don't already have it, load it
    if (!vendorsData) {
      dispatch(getVendors({ currentPage: 1, pageSize: 250 }));
    }
    // if we are in edit mode and dont have the categories list already, get it
    if (!categories) {
      dispatch(getCategories());
    }
    // get the merchant details
    dispatch(getMerchantDetails(merchantId));

    // if a strategyId is passed in, then we are viewing an existing strategy
    if (strategyId) {
      // get the strategy info for this specific strategy
      dispatch(getStrategyInfo({ strategyId }));
    } else {
      // they are creating a new strategy, put it in edit mode
      setEditMode(true);
    }
  }, []);

  const getVendorNameById = (vendorId) => {
    const vendor = vendorsData.vendors.find(vendor => vendor.vendorId === vendorId);
    return vendor?.name || '';
  }

  const getFacilityNameById = (facilityId) => {
    for (const item of vendorsData.vendors) {
      const facility = item.facilities.find(f => f.id === facilityId);
      if (facility) return facility.name;
    }
    return '';
  }

  const onCancelButtonPressed = (isSubmitting, resetForm) => {
    if (isSubmitting) return;
    if (strategyInfo && editMode) {
      resetForm();
      setEditMode(false);
    } else if (merchantId) {
      navigate(`/admin/routing/${merchantId}`);
    } else {
      navigate(`/admin/routing`);
    }
  }

  const onRoutingStrategyCreated = () => {
    if (merchantId) {
      navigate(`/admin/routing/${merchantId}`);
    } else {
      navigate(`/admin/routing`);
    }
  }

  const onRoutingStrategyUpdated = () => {
    if (merchantId) {
      navigate(`/admin/routing/${merchantId}`);
    } else {
      navigate(`/admin/routing`);
    }
  }

  const onRoutingStrategyDeleted = () => {
    if (merchantId) {
      navigate(`/admin/routing/${merchantId}`);
    } else {
      navigate(`/admin/routing`);
    }
  }

  const transformCountryCodeToMultiSelect = (countryCode) => {
    const country = CountryList.find(country => country.CountryCode === countryCode);
    return { value: countryCode, label: `${countryCode} - ${country.Name}`};
  }

  const transformCategoryToMultiSelect = (categoryId) => {
    const category = categories.find(category => category.id === categoryId);
    if (!category) {
      console.error('Filter contains non-existant category :', categoryId);
      return { value: categoryId, label: categoryId };
    } 
    return { value: category.id, label: category.title };
  }

  const parseFilterExpression = (filterExpression) => {
    if (!filterExpression) {
      return null;
    }

    // in case we get a filter expression with curly double quotes, replace them with straight double quotes
    filterExpression = filterExpression.replace(/“|”/g, '"');
    // do the same for single quotes
    filterExpression = filterExpression.replace(/‘|’/g, '\'');

    // define regular expressions for different parts of the data string
    const countryPattern = /input1.OrderItemDataPoints.OrderShipCountryCode == "(\S+)"/g;
    const metadataEqualsPattern = /input1.Metadata.(\S+) (\S+) "+(\S+)"+/g;
    const metadataContainsPattern = /input1.Metadata.(\S+)\.Contains\("(\S+)"\)/g;
    const categoryPattern = /category == "(\S+)"/g;
    const quantityPattern = /input1.OrderItemDataPoints.Quantity (\S+) (\S+)/g;

    const filters = [];

    // extract country codes
    let countryMatch;
    let OrderShipCountryCode = null;
    while ((countryMatch = countryPattern.exec(filterExpression)) !== null) {
      if (OrderShipCountryCode === null) {
        OrderShipCountryCode = {
          condition: 'ShipToCountry',
          logic: 'is equal to',
          value: [transformCountryCodeToMultiSelect(countryMatch[1])],
          value2: '',
        } 
      } else {
        // if there are multiple country codes, we need to add them to the value
        OrderShipCountryCode.value.push(transformCountryCodeToMultiSelect(countryMatch[1]));
      }
    }
    // if there were country code, then add them to our filter array
    if (OrderShipCountryCode) {
      filters.push(OrderShipCountryCode);
    }
    
    // now do the same for categories (similar to countries where wer concat them all together)
    let categoryMatch;
    let ProductCategoryName = null;
    while ((categoryMatch = categoryPattern.exec(filterExpression)) !== null) {
      if (ProductCategoryName === null) {
        ProductCategoryName = {
          condition: 'ProductCategory',
          logic: 'is equal to',
          value: [transformCategoryToMultiSelect(categoryMatch[1])],
          value2: '',
        }
      } else {
        ProductCategoryName.value.push(transformCategoryToMultiSelect(categoryMatch[1]));
      }
    }
    // if there were categories, then add them to our filter array
    if (ProductCategoryName) {
      filters.push(ProductCategoryName);
    }

    // now do the same for quantities
    // grab the first occurance of the quantity pattern
    const quantityMatch = quantityPattern.exec(filterExpression);
    if (quantityMatch) {
      // we found a quanity, is there another one?
      const quantityMatch2 = quantityPattern.exec(filterExpression);
      if (quantityMatch2) {
        // there are two quantities, add them to the filter array
        filters.push({
          condition: 'Quantity',
          logic: 'is between',
          value: quantityMatch[2],
          value2: quantityMatch2[2],
        });
      } else {
        // there is only one quantity, add it to the filter array
        filters.push({
          condition: 'Quantity',
          logic: convertSymbolToStringLogic(quantityMatch[1]),
          value: quantityMatch[2],
          value2: '',
        });
      }
    }

    // now do the same for the metadata.  Just like quantity, it could have a between or not.  
    let metadataEqualsMatch;
    while ((metadataEqualsMatch = metadataEqualsPattern.exec(filterExpression)) !== null) {
      filters.push({
        condition: metadataEqualsMatch[1],
        logic: convertSymbolToStringLogic(metadataEqualsMatch[2]),
        value: metadataEqualsMatch[3],
        value2: '',
      });
    }

    // now do the same for the metadata.  Just like quantity, it could have a between or not.  
    let metadataContainsMatch;
    while ((metadataContainsMatch = metadataContainsPattern.exec(filterExpression)) !== null) {
      filters.push({
        condition: metadataContainsMatch[1],
        logic: 'contains',
        value: metadataContainsMatch[2],
        value2: '',
      });
    }

    // now that we have all the metadata, check to see if any of them have the exact same condition.  If they do, we need to combine them
    const metadataConditions = filters.filter(filter => filter.condition !== 'ShipToCountry' && filter.condition !== 'ProductCategory' && filter.condition !== 'Quantity').map(filter => filter.condition);
    const uniqueMetadataConditions = [...new Set(metadataConditions)];
    for (let i = 0; i < uniqueMetadataConditions.length; i++) {
      const condition = uniqueMetadataConditions[i];
      const matchingFilters = filters.filter(filter => filter.condition === condition);
      if (matchingFilters.length > 1) {
        // there are multiple filters with the same condition, we need to combine them
        let combinedFilter = {
          condition: matchingFilters[0].condition,
          logic: 'is between',
          value: matchingFilters[0].value,
          value2: matchingFilters[1].value,
        };
        
        filters.push(combinedFilter);
      }
    }
    // delete the original 2 filters that were combined
    for (let i = 0; i < uniqueMetadataConditions.length; i++) {
      const condition = uniqueMetadataConditions[i];
      const matchingFilters = filters.filter(filter => filter.condition === condition);
      if (matchingFilters.length > 1) {
        filters.splice(filters.indexOf(matchingFilters[0]), 1);
        filters.splice(filters.indexOf(matchingFilters[1]), 1);
      }
    }
    
    return filters;
  }

  const convertStringLogicToSymbol = (logic) => {
    switch (logic) {
      case 'is equal to':
        return '==';
      case 'is less than':
        return '<';
      case 'is greater than':
        return '>';
      default:
        return logic;
    }
  }

  const convertSymbolToStringLogic = (logic) => {
    switch (logic) {
      case '==':
        return 'is equal to';
      case '<':
        return 'is less than';
      case '>':
        return 'is greater than';
      default:
        return logic;
    }
  }

  const buildFilterExpression = (filters) => {
    let filterExpression = '';

    // loop through each filter and build the filter expression
    for (let i = 0; i < filters.length; i++) {
      const filter = filters[i];
      if (i > 0) {
        filterExpression += ' AND ';
      }

      if (filter.condition === 'ShipToCountry') {
        filterExpression += '(';
        for (let j = 0; j < filter.value.length; j++) {
          if (j > 0) {
            filterExpression += ' OR ';
          }
          filterExpression += `input1.OrderItemDataPoints.OrderShipCountryCode == "${filter.value[j].value}"`;
        }
        filterExpression += ')';
      } else if (filter.condition === 'ProductCategory') {
        filterExpression += 'input1.OrderItemDataPoints.ProductCategoryName.Any(category => ';
        for (let j = 0; j < filter.value.length; j++) {
          if (j > 0) {
            filterExpression += ' || ';
          }
          filterExpression += `category == "${filter.value[j].value}"`;
        }
        filterExpression += ')';
      } else if (filter.condition === 'Quantity') {
        // if the logic 'is between' is selected, we need to add the second value
        if (filter.logic === 'is between') {
          filterExpression += `input1.OrderItemDataPoints.Quantity >= ${filter.value} AND input1.OrderItemDataPoints.Quantity <= ${filter.value2}`;
        } else {
          filterExpression += `input1.OrderItemDataPoints.Quantity ${convertStringLogicToSymbol(filter.logic)} ${filter.value}`;
        }
      } else {
        // logic for all other metadata can only be 'contains' or 'equals'
        if (filter.logic === 'contains') {
          filterExpression += `input1.Metadata.${filter.condition}.Contains("${filter.value}")`;
        } else  { // equals
          filterExpression += `input1.Metadata.${filter.condition} == "${filter.value}"`;
        }
      }
    }

    return filterExpression;
  }

  const defineInitialValues = (strategyInfo) => {
    const blocks = [];

    if (!strategyInfo) {
      blocks.push({
        blockName: '',
        groups: [{
          groupName: '',
          orderingAlgorithm: '',
          tiebreakerOrderingAlgorithm: '',
          vendors: [{
            vendorId: '',
            facilityId: '',
            rank: '0',
          }]
        }],
        filterInfo: null,
        retryRules: {
          rule1: 'Full',
          rule2: '',
          rule3: '',
        }
      })
    } else {
      for (let i = 0; i < strategyInfo.assignedBlocks.length; i++) {
        const b = strategyInfo.assignedBlocks[i];
        const block = {
          id: b.id,
          blockName: b.name || '',
          groups: [],
          filterInfo: null,
          retryRules: {
            rule1: b.retryActions.retryAction1,
            rule2: b.retryActions.retryAction2,
            rule3: b.retryActions.retryAction3,
          }
        };

        for (let j = 0; j < b.vendorGroups.length; j++) {
          let group = {
            groupName: b.vendorGroups[j].vendorGroupId || '',
            orderingAlgorithm: b.vendorGroups[j].orderingAlgorithm,
            tiebreakerOrderingAlgorithm: b.vendorGroups[j].tiebreakerOrderingAlgorithm || '',
            vendors: []
          };

          for (let k = 0; k < b.vendorGroups[j].vendors.length; k++) {
            for (let l = 0; l < b.vendorGroups[j].vendors[k].vendorFacilities.length; l++) {
              let vendor = {
                vendorId: b.vendorGroups[j].vendors[k].vendorId,
                facilityId: b.vendorGroups[j].vendors[k].vendorFacilities[l].id,
                rank: String(b.vendorGroups[j].vendors[k].vendorFacilities[l].rank),
              }
              group.vendors.push(vendor);
            }
            
          }
          block.groups.push(group);
        }

        // if there are filters, we need to parse them out and put them in the correct format
        if (!b.filter || b.filter.length === 0) {
          block.filterInfo = null;
        } else {
          // there are filters, initialize the filterInfo object
          // before adding the filters, make sure all parts are valid.  If not, do not use them (this gets rid of old broken filters)
          if (b?.filter?.[0]?.rules?.[0]?.expression && b?.filter?.[0]?.rules?.[0]?.ruleName) {
            block.filterInfo = {
              filterExpression: b.filter[0].rules[0].expression,
              filterName: b.filter[0].rules[0].ruleName || '',
              filters: parseFilterExpression(b.filter[0].rules[0].expression)
            }
          } else {
            block.filterInfo = null;
          }
        }

        blocks.push(block);
      }
    }

    return {
      strategyName: strategyInfo?.routingStrategyName || '',
      isActive: strategyInfo?.isActive || false,
      blocks,
    }
  };

  let errorMessage;

  if (vendorsErrors) {
    errorMessage = (
      <div className="data-load-failed">
        <ExclamationCircle /> Vendors data failed to load. Refresh the page to try again.
      </div>
    );
  } else if (strategyInfoError) {
    errorMessage = (
      <div className="data-load-failed">
        <ExclamationCircle /> Strategy information failed to load. Refresh the page to try again.
      </div>
    );
  } else if (merchantDetailsErrors) {
    errorMessage = (
      <div className="data-load-failed">
        <ExclamationCircle /> Merchant data failed to load. Refresh the page to try again.
      </div>
    );
  } else if (categoriesErrors) {
    errorMessage = (
      <div className="data-load-failed">
        <ExclamationCircle /> Category data failed to load. Refresh the page to try again.
      </div>
    );
  }

  return (
    <div className="strategy-edit-create">
      {(vendorsLoading || createStrategyLoading || updateStrategyLoading || deleteStrategyLoading || strategyInfoLoading || categoriesLoading || merchantDetailsLoading) && <LoadingAnimation />}
      {((vendorsData && merchantDetails && categories && (strategyId ? !!strategyInfo : true)) || errorMessage) && (
        <Formik
          enableReinitialize
          initialValues={defineInitialValues(strategyInfo)}
          validationSchema={() =>
            object().shape({
              strategyName: string().required('Strategy Name is required'),
              blocks: array().of(
                object().shape({
                  blockName: string().required('Block Name is required'),
                  groups: array().of(
                    object().shape({
                      groupName: string().required('Group Name is required'),
                      orderingAlgorithm: string().required('Sorting Mechanism is required'),
                      tiebreakerOrderingAlgorithm: string(),
                      vendors: array().of(
                        object().shape({
                          vendorId: string().required('Vendor Name is required'),
                          facilityId: string().required('Facility Name is required'),
                          rank: string().required('Rank is required'),
                        })
                      ).min(1, 'At least one vendor must be added')
                    })
                  ),
                  // add in the validation for filterInfo and all it's children.  Filter info is only required if it's not null
                  filterInfo: object().shape({
                    filterName: string().when('filters', {
                      is: (filters) => filters && filters.length > 0,
                      then: () => string().required('Filter Name is required'),
                      otherwise: () => string(),
                    }),
                    filters: array().of(
                      object().shape({
                        condition: string().required('Condition is required').notOneOf(['-'], 'Condition is required'),
                        logic: string().required('Logic is required'),
                        value: mixed().test(
                          'is-string-or-array',
                          'Value is required',
                          value => {
                            if (Array.isArray(value)) {
                              return array()
                                .of(
                                  object().shape({
                                    value: string().required('Value is required'),
                                    label: string().required('Label is required'),
                                  })
                                )
                                .min(1, 'At least one value must be added')
                                .isValidSync(value);
                            }
                            return string().required('Value is required').isValidSync(value);
                          }
                        ),
                        value2: string().when('logic', {
                          is: (logic) => logic === 'is between',
                          then: () => string().required('Value2 is required').test(
                            'is-less-than-or-equal',
                            'Should be ≥ Value1',
                            function(value2) {
                              const value1 = this.resolve(ref('value'));
                              return !value1 || !value2 || parseInt(value1) <= parseInt(value2);
                            }
                          ),
                          otherwise: () => string().notRequired(),
                        }),
                      })
                    ),
                  }).notRequired(),
                  retryRules: object().shape({
                    rule1: string().required('Default is required'),
                    rule2: string().required('Retry Rule 1 is required'),
                    rule3: string().required('Retry Rule 2 is required'),
                  }),
                })
              ),
            })
          }
          onSubmit={async (values) => {
            const routingStrategy = {
              "merchantId": merchantId,
              "routingStrategyName": values.strategyName,
              "isActive": values.isActive,
              "assignedBlocks": [],
            } 

            // if we are editing an existing strategy, we need to add the id
            if (strategyId) {
              routingStrategy.id = strategyId;
            }

            for (let x = 0; x < values.blocks.length; x++) {
              const block = values.blocks[x];
              const data = {
                "name": block.blockName,
                "isRuleEngineEnabled": true, 
                "filter": [],
                "vendorGroups": [],
                "retryActions": {
                  "retryAction1": block.retryRules.rule1,
                  "retryAction2": block.retryRules.rule2,
                  "retryAction3": block.retryRules.rule3,
                },
                "order": x + 1,
                "isDefault": false
              }

              // if we are editing an existing strategy, we need to add the id
              if (block.id) {
                data.id = block.id;
              }

              // get the actual filterExpression string to send to the server (if there is one)
              if (block.filterInfo) {
                const filterExpression = buildFilterExpression(block.filterInfo.filters);

                data.filter.push({
                  "workflowName": 'Routing',
                  "rules": [
                    {
                      "ruleName": block.filterInfo.filterName,
                      "errorMessage": "Error",
                      "enabled": true,
                      "expression": filterExpression,
                      "successEvent": "true"
                    }
                  ]
                })
              } else {
                data.filter = null;
                data.isRuleEngineEnabled = false;
              }

              for (let j = 0; j < block.groups.length; j++) {
                data.vendorGroups.push({
                  "vendorGroupId": block.groups[j].groupName,
                  "orderingAlgorithm": block.groups[j].orderingAlgorithm,
                  "tiebreakerOrderingAlgorithm": block.groups[j].orderingAlgorithm === 'Random' ? null : block.groups[j].tiebreakerOrderingAlgorithm === "" ? null : block.groups[j].tiebreakerOrderingAlgorithm,
                  "vendors": [
                  ]
                })

                for (let k = 0; k < block.groups[j].vendors.length; k++) {
                  const vendorId = block.groups[j].vendors[k].vendorId;

                  // check to see if this vendor has already been added
                  const vendorIndex = data.vendorGroups[j].vendors.findIndex(vendor => vendor.vendorId === vendorId);

                  if (vendorIndex >= 0) {
                    // this vendor was previously added, no need to add the vendor again, just add their facility
                    data.vendorGroups[j].vendors[vendorIndex].vendorFacilities.push({
                      "id": block.groups[j].vendors[k].facilityId,
                      "name": getFacilityNameById(block.groups[j].vendors[k].facilityId),
                      "rank": parseInt(block.groups[j].vendors[k].rank) || 0,
                    });
                  } else {
                    data.vendorGroups[j].vendors.push({
                      "vendorId": block.groups[j].vendors[k].vendorId,
                      "vendorName": getVendorNameById(block.groups[j].vendors[k].vendorId),
                      "vendorFacilities": [{
                        "id": block.groups[j].vendors[k].facilityId,
                        "name": getFacilityNameById(block.groups[j].vendors[k].facilityId),
                        "rank": parseInt(block.groups[j].vendors[k].rank) || 0,
                      }],
                    })
                  }
                }
              }

              routingStrategy.assignedBlocks.push(data);
            }

            if (editMode && strategyInfo) {
              dispatch(updateRoutingStrategy({routingStrategy, cb: onRoutingStrategyUpdated}));
            } else {
              dispatch(createRoutingStrategy({routingStrategy, cb: onRoutingStrategyCreated}));
            }
          }}
        >
          {({
            values,
            errors,
            setFieldValue,
            handleChange,
            handleSubmit,
            isSubmitting,
            submitCount,
            validateForm,
            resetForm,
          }) => (
            <form onSubmit={handleSubmit}>
              <Card className={editMode ? 'no-bot-padding' : ''}>
                <div className="strategy-header-container">
                  <div className="strategy-name">
                    <ButtonIcon icon={<ArrowLeft />} onClick={() => navigate(`/admin/routing/${merchantId}`)} />
                    {strategyInfo && !editMode ? strategyInfo.routingStrategyName : strategyInfo && editMode ? 'Edit Routing Strategy' : errorMessage ? 'Routing Strategy' : 'Create Routing Strategy'}
                  </div>
                  <div>
                    {strategyInfo && !editMode ? (   
                      <AdditionalStrategyOptions 
                        strategyId={strategyId}
                        isActive={strategyInfo.isActive}
                        onEditStrategy={() => setEditMode(true)}
                        onRoutingStrategyDeleted={() => onRoutingStrategyDeleted()}
                      />
                    ) : (
                      <div className="action-buttons">
                        <Button
                          variant="secondary"
                          size="medium"
                          label="Cancel"
                          onClick={() => onCancelButtonPressed(isSubmitting, resetForm)}
                          disabled={createStrategyLoading || updateStrategyLoading || deleteStrategyLoading}
                        />
                        {!errorMessage && (
                          <Button
                            variant="primary"
                            size="medium"
                            label="Save Strategy"
                            onClick={() => (isSubmitting ? null : handleSubmit())}
                            disabled={createStrategyLoading || updateStrategyLoading || deleteStrategyLoading}
                          />
                        )}
                      </div>
                    )}
                  </div>
                </div>
                {editMode && !errorMessage && (
                  <div className="strategy-name-input">
                    <Input
                      label="Strategy Name"
                      name="strategyName"
                      value={values.strategyName}
                      readonly={!editMode}
                      onChange={handleChange}
                      placeholder="Add a Strategy Name"
                      errorMessage={submitCount > 0 && errors.strategyName}
                    />
                  </div>
                )}
                {errorMessage && (
                  <div className="error-message">
                    {errorMessage}
                  </div>
                
                )}
              </Card>
              {!errorMessage && values.blocks.map((block, index) => (
                <Block
                  key={index}
                  blockIndex={index}
                  values={values}
                  editMode={editMode}
                  isNewStrategy={strategyInfo ? false : true}
                  data={block}
                  vendorsData={vendorsData}
                  categories={categories}
                  errors={errors}
                  handleChange={handleChange}
                  handleSubmit={handleSubmit}
                  isSubmitting={isSubmitting}
                  submitCount={submitCount}
                  setFieldValue={setFieldValue}
                  validateForm={validateForm}
                />
              ))}
            </form>
          )}
        </Formik>
      )}
    </div>
  )
}

export default StrategyEditCreate;