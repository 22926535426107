import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import {
  usersDataSelector,
  usersLoadingSelector,
  usersErrorsSelector
} from "app/store/selectors/user";
import {
  getUsers,
  reset,
  updateUserStatus,
  deleteUser,
  requestForgotPassword
} from "app/store/actions/user";
import {
  Pagination,
  LoadingAnimation,
  SearchBar,
  StatusChip,
  ButtonIcon,
  TabbedMenu,
  Table,
  TableHeader,
  Button,
  Modal,
  Card,
  Link
} from "app/components";
import { ExclamationCircle, ThreeDotsVertical } from "react-bootstrap-icons";
import { formatUsersRolesName } from "../utils";
import { usePermission } from 'app/permissions';
import moment from "moment";
import "./index.scss";

const tabs = [
  {
    path: "?status=active",
    label: "Active",
  },
  {
    path: "?status=inactive",
    label: "Inactive",
  },
  {
    path: "?status=all",
    label: "All Users",
  },
];

const UsersView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [searchParams] = useSearchParams();
  const [searchString, setSearchString] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("active");
  const [selectedSortBy, setSelectedSortBy] = useState("email");
  const [selectedSortDir, setSelectedSortDir] = useState("asc");
  const [updateUserStatusModal, setUpdateUserStatusModal] = useState(false);
  const [deleteUserModal, setDeleteUserModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const usersData = useSelector(usersDataSelector);
  const loading = useSelector(usersLoadingSelector);
  const errorsFound = useSelector(usersErrorsSelector);

  const currentPage = parseInt(searchParams.get("page") || "1", 10);
  const pageSize = parseInt(searchParams.get("pageSize") || "50", 10);
  const sortBy = searchParams.get("sortBy") || "email";
  const sortDir = searchParams.get("sortDir") || "asc";

  // get user permissions
  const canManageUsers = usePermission('users', 'users_create');
  const canManageRoles = usePermission('users', 'roles_create');

  const userStatusLabel = (user) =>
    user.status === "enabled" ? "Deactivate" : "Activate";

  const userActionOptions = (user, dispatch, navigate) => [
    ...(canManageUsers ? [{
      value: "Edit User",
      label: "Edit User",
      onClick: () => {
        dispatch(reset());
        navigate(`/admin/users/${user.id}`);
      },
    }] : []),
    {
      value: `${userStatusLabel(user)} User`,
      label: `${userStatusLabel(user)} User`,
      onClick: () => {
        setUpdateUserStatusModal(true);
        setSelectedUser(user);
      },
    },
    {
      value: "Reset Password",
      label: "Reset Password",
      onClick: () => {
        dispatch(requestForgotPassword({ email: user.email, isAdmin: true }));
      },
    },
    ...(canManageUsers ? [{
      value: "Delete User",
      label: "Delete User",
      destructive: true,
      onClick: () => {
        setDeleteUserModal(true);
        setSelectedUser(user);
      },
    }] : [])
  ];

  useEffect(() => {
    dispatch(getUsers({ searchString, selectedStatus, selectedSortBy,selectedSortDir,currentPage, pageSize }));
  }, [searchString, selectedStatus, selectedSortBy, selectedSortDir, currentPage, pageSize, dispatch]);

  useEffect(() => {
    handleLocationSearch();
  }, [location.search]);

  useEffect(() => {
    handleSearchChange();
  }, [selectedStatus, selectedSortBy, selectedSortDir]);

  const handleLocationSearch = () => {
    const searchParams = new URLSearchParams(location.search);

    // set selected status if it has been changed...
    const status = searchParams.get("status");
    if (status && status !== selectedStatus) {
      setSelectedStatus(status);
    }

    // set sort by if it has been changed...
    const sortBy = searchParams.get("sortBy");
    if (sortBy && sortBy !== selectedSortBy) {
      setSelectedSortBy(sortBy);
    }

    // set sort direction if it has been changed...
    const sortDir = searchParams.get("sortDir");
    if (sortDir && sortDir !== selectedSortDir) {
      setSelectedSortDir(sortDir);
    }
  };

  const handleSearchChange = () => {
    dispatch(
      getUsers({
        searchString,
        selectedStatus,
        selectedSortBy,
        selectedSortDir,
        currentPage,
        pageSize,
      })
    );
  };

  return (
    <div className="users-view">
      {loading && <LoadingAnimation />}
      {(usersData || canManageUsers) && (
        <Card>
          <div className="users-view-header d-flex justify-content-between">
              Users
            <div className="d-flex">
              {canManageRoles && (
                <Button
                  className="mx-2"
                  variant="secondary"
                  size="medium"
                  label={"Manage Roles"}
                  onClick={() => {
                    dispatch(reset());
                    navigate(`/admin/users/roles`);
                  }}
                />
              )}
              {canManageUsers && (
                <Button
                  variant="primary"
                  size="medium"
                  label={"Create User"}
                  onClick={() => {
                    dispatch(reset());
                    navigate(`/admin/users/create-user`);
                  }}
                />
              )}
            </div>
          </div>
          <TabbedMenu tabs={tabs} />
          <SearchBar
            searchPlaceholderText="Search for a User"
            onSearchStringUpdated={setSearchString}
            debounceDelay={500}
          />
          <Table size="medium">
            <TableHeader
              sortBy={sortBy}
              sortDir={sortDir}
              options={[
                { id: "email", label: "Email", orderable: true },
                { id: "name", label: "Name", orderable: true },
                { id: "roles", label: "Roles", orderable: true },
                { id: "createdOn", label: "Created On", orderable: true },
                { id: "status", label: "Status", align: "center" },
                { id: "actions", label: "Actions", align: "center" },
              ]}
            />
            <tbody className="table-body">
              {usersData?.users.map((user) => (
                <tr className="user-row" key={user.id}>
                  <td>
                    <Link
                      label={user.email}
                      url={`/admin/users/${user.id}`}
                      onClick={() => {
                        dispatch(reset());
                        navigate(`/admin/users/${user.id}`)
                      }}
                    />
                  </td>
                  <td>{`${user.firstName} ${user.lastName}`}</td>
                  <td>
                    {user.roles
                      .map((r) => formatUsersRolesName(r?.name))
                      .join(", ") || "-"}
                  </td>
                  <td>{moment(user.dateCreated).format("MM/DD/YY")}</td>
                  <td className="user-status">
                    <StatusChip type="user" status={user.status} />
                  </td>
                  <td className="user-actions-menu">
                    <ButtonIcon
                      icon={<ThreeDotsVertical />}
                      options={userActionOptions(user, dispatch, navigate)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          {errorsFound && !loading && (
            <div className="user-load-failed"><ExclamationCircle />User Data failed to load. Refresh the page to try again.</div>
          )}
          {usersData?.users.length === 0 ? (
            <div className="no-matching-results">No Matching Results Found</div>
          ) : (
            <Pagination totalItems={usersData?.total || 0} />
          )}
          {updateUserStatusModal && (
            <Modal
              secondaryButtonLabel="Cancel"
              secondaryButtonOnClick={() => {
                setUpdateUserStatusModal(false);
                setSelectedUser(null);
              }}
              primaryButtonLabel={`Yes, ${userStatusLabel(selectedUser)} It`}
              primaryButtonVariant="primary"
              primaryButtonOnClick={() => {
                setUpdateUserStatusModal(false);
                dispatch(
                  updateUserStatus({
                    id: selectedUser.id,
                    status: userStatusLabel(selectedUser)?.toLowerCase(),
                    cb: () => handleSearchChange(),
                  })
                );
              }}
              onClose={() => {
                setUpdateUserStatusModal(false);
                setSelectedUser(null);
              }}
            >
              <div>
                Are you sure you want to{" "}
                {userStatusLabel(selectedUser)?.toLocaleLowerCase()}{" "}
                <b>{selectedUser?.email}</b>?
              </div>
            </Modal>
          )}
          {deleteUserModal && (
            <Modal
              secondaryButtonLabel="Cancel"
              secondaryButtonOnClick={() => {
                setDeleteUserModal(false);
                setSelectedUser(null);
              }}
              primaryButtonLabel="Yes, Delete It"
              primaryButtonVariant="primary"
              primaryButtonDestructive
              primaryButtonOnClick={() => {
                setDeleteUserModal(false);
                dispatch(
                  deleteUser({
                    id: selectedUser.id,
                    cb: () => handleSearchChange(),
                  })
                );
              }}
              onClose={() => {
                setDeleteUserModal(false);
                setSelectedUser(null);
              }}
            >
              <div>
                Are you sure you want to delete user{" "}
                <b>{selectedUser?.email}</b>?
              </div>
              <div>This action is not reversible.</div>
            </Modal>
          )}
        </Card>
      )}
    </div>
  );
};

export default UsersView;
