import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { Modal, Button, LoadingAnimation } from 'app/components';
import { getVendorDetails } from 'app/store/actions/vendor';
import { vendorDetailsSelector, vendorDetailsLoadingSelector, vendorDetailsErrorsSelector } from 'app/store/selectors/vendor';
import { Person, SuitcaseLg, Telephone, Envelope } from 'react-bootstrap-icons';
import './index.scss';

const VendorInfoModal = props => {
  const { vendorId, showVendorInfoModal } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const vendorDetails = useSelector(vendorDetailsSelector);
  const vendorsLoading = useSelector(vendorDetailsLoadingSelector);
  const vendorErrors = useSelector(vendorDetailsErrorsSelector);

  useEffect(() => {
    dispatch(getVendorDetails({ vendorId }));
  }, [dispatch, vendorId]);

  return (
    <Modal
      className="vendor-info-modal"
      title="Vendor Contact Information"
      hideButtons={true}
      onClose={() => showVendorInfoModal(null)}
    >
      {vendorsLoading && (
        <div className="vendor-data-loading">
          <LoadingAnimation />
        </div>
      )}
      {vendorDetails && !vendorsLoading && (
        <div className="vendor-info-content">
          <div className="vendor-contact-item">
            <Person />
            <span className="contact-detail">{vendorDetails.contacts[0]?.name || '-'}</span>
          </div>
          <div className="vendor-contact-item">
            <SuitcaseLg />
            <span className="contact-detail">{vendorDetails.contacts[0]?.role || '-'}</span>
          </div>
          <div className="vendor-contact-item">
            <Telephone />
            <span className="contact-detail">{vendorDetails.contacts[0]?.phone || '-'}</span>
          </div>
          <div className="vendor-contact-item">
            <Envelope />
            <span className="contact-detail">{vendorDetails.contacts[0]?.email || '-'}</span>
          </div>
          <div className="action-buttons">
            <Button
              size="small"
              variant="secondary"
              label="Go to Vendor Portal"
              onClick={() => window.open(vendorDetails.url, '_blank')}
            />
            <Button
              size="small"
              variant="primary"
              label="View Vendor"
              onClick={() => navigate("/admin/vendors/" + vendorId)}
            />
          </div>
        </div>
      )}
      {vendorErrors && (
        <div className="vendor-info-error">
          Vendor Information failed to load.
        </div>
      )}
    </Modal>
  );
}

export default VendorInfoModal;
