import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import './index.scss';

const OrdersFilterMenu = (props) => {
  const { disabled } = props;
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedFilterIndex, setSelectedFilterIndex] = useState(0);

  const filterArray = [
    { name: 'All', customFilter: false, isPinned: false, options: []},
    { name: 'Image Issue', customFilter: false, isPinned: true, options: [{ key: 'status', value: 'imageerror' }]},
    { name: 'Needs Personalization', customFilter: false, isPinned: true, options: [{ key: 'status', value: 'needspersonalization' }]},
    { name: 'Out of Stock', customFilter: true, isPinned: false, options: [{ key: 'status', value: 'outofstock' }]},
    { name: 'Vendor API Issue', customFilter: true, isPinned: false, options: [{ key: 'status', value: 'vendorapiissue' }]},
  ];

  useEffect(() => {
    const status = searchParams.get('status');

    if (status) {
      // find the filter that matches the status in the URL
      const filterIndex = filterArray.findIndex(filter => 
        filter.options.some(option => option.value === status)
      );
      if (filterIndex !== -1) {
        setSelectedFilterIndex(filterIndex);
      }
    }
  }, [searchParams]);

  const onFilterUpdated = (filter, index) => {
    const { options } = filter;

    const updatedSearchParams = new URLSearchParams();

    options.forEach(option => {
      updatedSearchParams.set(option.key, option.value);
    });

    setSearchParams(updatedSearchParams);
    setSelectedFilterIndex(index);
  };
      
  return (
    <div className="orders-filter-menu">
      <div className="filter-fields">
        {filterArray.map((filter, index) => {
          return (
            <div key={index} className={`filter-field ${selectedFilterIndex === index ? 'selected' : ''}`}>
              <div
                className="filter-name"
                onClick={() => !disabled ? onFilterUpdated(filter, index) : null}
              >
                {filter.name}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default OrdersFilterMenu;
