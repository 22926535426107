import { put, takeEvery, call, all, takeLatest, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import {
  getMerchants,
  getMerchantsSuccess,
  getMerchantsFailure,
  getMerchantDetails,
  getMerchantDetailsSuccess,
  getMerchantDetailsFailure,
  createMerchant,
  createMerchantSuccess,
  createMerchantFailure,
  updateMerchant,
  updateMerchantSuccess,
  updateMerchantFailure,
  updateMerchantsParent,
  updateMerchantsParentSuccess,
  updateMerchantsParentFailure,
  updateMerchantsChildren,
  updateMerchantsChildrenSuccess,
  updateMerchantsChildrenFailure,
  removeAllAssociations,
  removeAllAssociationsSuccess,
  removeAllAssociationsFailure,
  getTemplates,
  getTemplatesSuccess,
  getTemplatesFailure,
  deleteTemplate,
  deleteTemplateSuccess,
  deleteTemplateFailure,
  updateTemplate,
  updateTemplateSuccess,
  updateTemplateFailure,
  getTemplate,
  getTemplateSuccess,
  getTemplateFailure,
  createTemplate,
  createTemplateSuccess,
  createTemplateFailure,
  renderTemplate,
  renderTemplateFailure,
  renderTemplateSuccess,
  getConfiguration,
  getConfigurationFailure,
  getConfigurationSuccess,
  getMerchantSchema,
  getMerchantSchemaSuccess,
  getMerchantSchemaFailure,
  updateMerchantConfiguration,
  updateMerchantConfigurationFailure,
  updateMerchantConfigurationSuccess
} from "app/store/actions/merchant"
import MerchantServices from 'app/services/merchantServices';
import TemplateServices from 'app/services/templateServices';
import FilesServices from 'app/services/filesServices';
import ConfigServices from 'app/services/configServices';
import { MERCHANT_SCHEMA } from 'app/services/configServices';
import { sampleDataTemplateSelector } from '../selectors/merchant';

function* fetchMerchants(action) {
  const { searchString, currentPage, pageSize, sortBy, sortDir } = action.payload;

  try {
    const data = yield call([MerchantServices, MerchantServices.getMerchants], searchString, currentPage, pageSize, sortBy, sortDir);

    data.merchants.sort((a, b) => {
      // fallback name if not present
      const nameA = a.name ? a.name.toLowerCase() : '';
      const nameB = b.name ? b.name.toLowerCase() : '';

      return nameA.localeCompare(nameB);
    });

    yield put(getMerchantsSuccess(data));
  } catch (error) {
    console.error('error', error);
    yield put(getMerchantsFailure(error));
  }
}

function* fetchMerchantDetails(action) {
  const merchantId = action.payload;
  try {
    const resp = yield call([MerchantServices, MerchantServices.getMerchantById], merchantId);
    yield put(getMerchantDetailsSuccess(resp));
  } catch (error) {
    console.error('error', error);
    yield put(getMerchantDetailsFailure(error));
  }
}

function* doCreateMerchant(action) {
  const { data, cb } = action.payload;
  try {
    const resp = yield call([MerchantServices, MerchantServices.createMerchant], data);
    yield put(createMerchantSuccess(resp));
    if (cb) cb(resp.id);
    toast.success("New Merchant Successfully Created", {
      theme: 'colored',
    });
  } catch (error) {
    console.error('error', error);
    yield put(createMerchantFailure(error));
    toast.error(error.toString(), {
      theme: 'colored',
    });
  }
}

function* doUpdateMerchant(action) {
  const { data, cb } = action.payload;

  try {
    const resp = yield call([MerchantServices, MerchantServices.updateMerchant], data);
    yield put(updateMerchantSuccess(resp));
    if (cb) cb(data.id);
    toast.success("Merchant Successfully Updated", {
      theme: 'colored',
    });
  } catch (error) {
    console.error('error', error);
    yield put(updateMerchantFailure(error));
    toast.error(error.toString(), {
      theme: 'colored',
    });
  }
}

function* doUpdateMerchantsParent(action) {
  const { merchantDetails, cb } = action.payload;

  // we are assigning a new parent to this merchant, if they have any existing children, we need to remove them first
  if (merchantDetails.children.length > 0) {
    const childrenSuccessfullyRemoved = [];

    // first remove all children from this parent
    for (let i = 0; i < merchantDetails.children.length; i++) {
      try {
        const childMerchant = merchantDetails.children[i];
        childMerchant.parentId = null;
        yield call([MerchantServices, MerchantServices.updateMerchant], childMerchant);
        childrenSuccessfullyRemoved.push(childMerchant);
      } catch (error) {
        console.error('error', error);
        break;
      }
    }

    // did all merchants get removed successfully?
    if (merchantDetails.children.length !== childrenSuccessfullyRemoved.length) {
      yield put(updateMerchantsParentFailure());
      toast.error("Merchant Association Failed To Update", {
        theme: 'colored',
      })
      return;
    }
  }

  // clear out the child merchants array
  merchantDetails.children = [];
  // remove the parent flag
  merchantDetails.isParent = false;

  // we have the entire parent object stored in the merchantDetails.  The api doesn't use that, but instead just needs the parent id
  merchantDetails.parentId = merchantDetails.parent.id;

  try {
    yield call([MerchantServices, MerchantServices.updateMerchant], merchantDetails);
    yield put(updateMerchantsParentSuccess(merchantDetails));
    if (cb) cb();
    toast.success("Merchant Association Successfully Updated", {
      theme: 'colored',
    });
  } catch (error) {
    console.error('error', error);
    yield put(updateMerchantsParentFailure(error));
    toast.error(error.toString(), {
      theme: 'colored',
    });
  }
}

function* doUpdateMerchantsChildren(action) {
  const { merchantDetails, updatedMerchantDetails, childrenToUpdate, cb } = action.payload;
  const childrenSuccessfullyAdded = [];

  // before adding or removing children, update the parent merchant to make sure they are a parent (only if they were not previously a parent)
  if (!merchantDetails.isParent) {
    try {
      merchantDetails.isParent = true;
      merchantDetails.parentId = null;
      yield call([MerchantServices, MerchantServices.updateMerchant], merchantDetails);
    } catch (error) {
      console.error('error', error);
      yield put(updateMerchantsChildrenFailure());
      toast.error("Merchant Association Failed To Update", {
        theme: 'colored',
      })
      return;
    }
  }

  // update all the merchants what were added or removed from the parent merchant
  for (let i = 0; i < childrenToUpdate.length; i++) {
    try {
      yield call([MerchantServices, MerchantServices.updateMerchant], childrenToUpdate[i]);
      childrenSuccessfullyAdded.push(childrenToUpdate[i]);
    } catch (error) {
      console.error('error', error);
      break;
    }
  }

  try {
    // did all merchants get updated successfully?
    if (childrenToUpdate.length === childrenSuccessfullyAdded.length) {
      updatedMerchantDetails.isParent = true;
      updatedMerchantDetails.parentId = null;
      yield call(fetchMerchants, { payload: { currentPage: 1, pageSize: 250 } });
      yield put(updateMerchantsChildrenSuccess(updatedMerchantDetails));
      toast.success("Merchant Association Successfully Updated", {
        theme: 'colored',
      });
      if (cb) cb();
    } else {
      yield put(updateMerchantsChildrenFailure());
      toast.error("Merchant Association Failed To Update", {
        theme: 'colored',
      })
    }
  } catch (error) {
    console.error('error', error);
    yield put(updateMerchantsChildrenFailure());
    toast.error("Merchant Association Failed To Update", {
      theme: 'colored',
    })
  }
}

function* doRemoveAllAssociations(action) {
  const { merchantDetails, cb } = action.payload;
  const childrenSuccessfullyRemoved = [];

  // are they are a parent trying to remove all their associations?
  if (merchantDetails.isParent) {
    // first remove all children from this parent 
    for (let i = 0; i < merchantDetails.children.length; i++) {
      try {
        const childMerchant = merchantDetails.children[i];
        childMerchant.parentId = null;
        yield call([MerchantServices, MerchantServices.updateMerchant], childMerchant);
        childrenSuccessfullyRemoved.push(childMerchant);
      } catch (error) {
        console.error('error', error);
        break;
      }
    }

    // did all merchants get removed successfully?
    if (merchantDetails.children.length === childrenSuccessfullyRemoved.length) {
      // now update the parent merchant to remove their parent flag
      try {
        merchantDetails.parent = null;
        merchantDetails.isParent = false;
        merchantDetails.children = [];
        yield call([MerchantServices, MerchantServices.updateMerchant], merchantDetails);
      } catch (error) {
        console.error('error', error);
        yield put(removeAllAssociationsFailure());
        toast.error("Merchant Association Failed To Update", {
          theme: 'colored',
        })
        return;
      }

      yield put(removeAllAssociationsSuccess(merchantDetails));
      toast.success("Merchant Association Successfully Updated", {
        theme: 'colored',
      });
      if (cb) cb();
    } else {
      yield put(removeAllAssociationsFailure());
      toast.error("Merchant Association Failed To Update", {
        theme: 'colored',
      })
    }
  } else {
    // they are a child merchant, just remove their parent
    try {
      merchantDetails.parent = null;
      merchantDetails.parentId = null;
      yield call([MerchantServices, MerchantServices.updateMerchant], merchantDetails);
      yield put(removeAllAssociationsSuccess(merchantDetails));
      toast.success("Merchant Association Successfully Updated", {
        theme: 'colored',
      });
      if (cb) cb();
    } catch (error) {
      console.error('error', error);
      yield put(removeAllAssociationsFailure());
      toast.error("Merchant Association Failed To Update", {
        theme: 'colored',
      })
    }
  }
}

function* fetchMerchantTemplates(action) {
  const merchantId = action.payload;
  try {
    const resp = yield call([TemplateServices, TemplateServices.getTemplates], merchantId);
    yield put(getTemplatesSuccess(resp));
  } catch (error) {
    console.error('error', error);
    yield put(getTemplatesFailure(error));
    toast.error("Failed to fetch merchant templates", {
      theme: 'colored',
    })
  }
}

function* deleteTemplateHandler(action) {
  const { templateId, merchantId } = action.payload;
  try {
    yield call([TemplateServices, TemplateServices.deleteTemplate], templateId);
    yield put(deleteTemplateSuccess());
    yield put(getTemplates(merchantId));
    toast.success("Template Successfully Deleted", {
      theme: 'colored',
    });
  } catch (error) {
    console.error('error', error);
    yield put(deleteTemplateFailure());
    toast.error("Failed to delete template", {
      theme: 'colored',
    })
  }
}

function* updateTemplateHandler(action) {
  const { values, cb } = action.payload;
  const data = {
    name: values.templateName,
    merchantId: values.merchantId,
    html: values.html,
    category: values.category,
    tags: "",
    description: values.templateDescription
  };

  try {
    yield call([TemplateServices, TemplateServices.updateTemplate], values.id, data);
    yield put(updateTemplateSuccess());
    yield put(getTemplates(values.merchantId));
    toast.success("Template Successfully Updated", {
      theme: 'colored',
    });
    if (cb) cb();
  } catch (error) {
    console.error('error', error);
    yield put(updateTemplateFailure());
    toast.error("Failed to update template", {
      theme: 'colored',
    })
  }
}

function* fetchTemplateHandler(action) {
  const templateId = action.payload;
  try {
    const resp = yield call([TemplateServices, TemplateServices.getTemplate], templateId);
    yield put(getTemplateSuccess(resp));
  } catch (error) {
    console.error('error', error);
    yield put(getTemplateFailure(error));
    toast.error("Failed to fetch template", {
      theme: 'colored',
    })
  }
}

function* createTemplateHandler(action) {
  const { values, cb } = action.payload;
  const data = {
    merchantId: values.merchantId,
    name: values.templateName,
    html: values.html,
    category: values.category,
    tags: "",
    description: values.templateDescription
  };
  try {
    yield call([TemplateServices, TemplateServices.createTemplate], data);
    yield put(createTemplateSuccess());
    yield put(getTemplates(values.merchantId));
    toast.success("Template Successfully Created", {
      theme: 'colored',
    });
    if (cb) cb();
  } catch (error) {
    console.error('error', error);
    yield put(createTemplateFailure());
    toast.error("Failed to create template", {
      theme: 'colored',
    })
  }
}

function* renderTemplateHandler(action) {
  const { templateId, cb } = action.payload;
  const errMsg = "Failed to render template";
  const samplePayload = yield select(sampleDataTemplateSelector);

  try {
    const resp = yield call([TemplateServices, TemplateServices.renderTemplate], templateId, samplePayload);
    if (resp.fileId) {
      const file = yield call([FilesServices, FilesServices.getFile], resp.fileId);
      yield put(renderTemplateSuccess(file));
      if (cb) cb(file);
    } else {
      console.error('error', resp);
      yield put(renderTemplateFailure());
      toast.error(errMsg, {
        theme: 'colored',
      })
    }
  } catch (err) {
    console.error('error', err);
    yield put(renderTemplateFailure());
    toast.error(errMsg, {
      theme: 'colored',
    })
  }
}

function* getConfigurationHandler(action) {
  try {
    const { merchantId } = action.payload;
    const resp = yield call([ConfigServices, ConfigServices.getConfiguration], merchantId, MERCHANT_SCHEMA);
    yield put(getConfigurationSuccess(resp));
  } catch (error) {
    console.error('error', error);
    yield put(getConfigurationFailure(error));
    toast.error("Failed to fetch configuration", {
      theme: 'colored',
    })
  }
}

function* getSchemaHandler() {
  try {
    const resp = yield call([ConfigServices, ConfigServices.getSchema], MERCHANT_SCHEMA);
    yield put(getMerchantSchemaSuccess(resp));
  } catch (error) {
    console.error('error', error);
    yield put(getMerchantSchemaFailure(error));
    toast.error("Failed to fetch schema", {
      theme: 'colored',
    })
  }
}

function* updateConfigurationHandler(action) {
  const { data, cb } = action.payload;
  try {
    yield call([ConfigServices, ConfigServices.updateConfiguration], cleanUpMerchantConfigData(data));
    yield put(updateMerchantConfigurationSuccess(data));
    toast.success("Configuration Successfully Updated", {
      theme: 'colored',
    });
    if (cb) cb();
  } catch (error) {
    console.error('error', error);

    // if we get an error on update, we have to try to create configuration (workaround for ConfigMS)...
    try {
      yield call([ConfigServices, ConfigServices.createConfiguration], data);
      yield put(updateMerchantConfigurationSuccess(data));
      toast.success("Configuration Successfully Updated", {
        theme: 'colored',
      });
      if (cb) cb();
    } catch (error) {
      console.log('error', error);
      yield put(updateMerchantConfigurationFailure(error));
      toast.error("Failed to update configuration", {
        theme: 'colored',
      })
    }
  }
}

function cleanUpMerchantConfigData(data){

  // cleanup old return address config fields, which got merged to new configs...
  // probably it will not be needed in future, when all configs get updated
  try {
    delete data.country_code;
    delete data.state;
    delete data.city;
    delete data.address_line_1;
    delete data.address_line_2;
    delete data.postal_code;
    delete data.phone;
    delete data.email;
    delete data.firstName;
    delete data.lastName;
  } catch (error) {
    console.error('error while cleaning up merchant config', error);
  }

  return data;
}

function* watchData() {
  yield takeEvery(getMerchants().type, fetchMerchants);
  yield takeEvery(getMerchantDetails.toString(), fetchMerchantDetails);
  yield takeEvery(createMerchant.toString(), doCreateMerchant);
  yield takeEvery(updateMerchant.toString(), doUpdateMerchant);
  yield takeEvery(updateMerchantsParent.toString(), doUpdateMerchantsParent);
  yield takeEvery(updateMerchantsChildren.toString(), doUpdateMerchantsChildren);
  yield takeEvery(removeAllAssociations.toString(), doRemoveAllAssociations);
  yield takeLatest(getTemplates.toString(), fetchMerchantTemplates);
  yield takeLatest(deleteTemplate.toString(), deleteTemplateHandler);
  yield takeLatest(updateTemplate.toString(), updateTemplateHandler);
  yield takeLatest(getTemplate.toString(), fetchTemplateHandler);
  yield takeLatest(createTemplate.toString(), createTemplateHandler);
  yield takeLatest(renderTemplate.toString(), renderTemplateHandler);
  yield takeLatest(getConfiguration.toString(), getConfigurationHandler);
  yield takeLatest(getMerchantSchema.toString(), getSchemaHandler);
  yield takeLatest(updateMerchantConfiguration.toString(), updateConfigurationHandler);
}

export default function* rootSaga() {
  yield all([
    watchData(),
  ]);
}